<template>
  <div class="vue-editor">
    <div class="editor-with-title">
      <div class="editor-header">{{ title }}</div>
      <ckeditor :editor="editor" @input="handleInput" :value="value"
                :config="editorConfig"></ckeditor>
    </div>
    <div class="error--text">{{ errorMsg }}</div>
  </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-editor-classic/src/classiceditor';
import ParagraphPlugin from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import EssentialsPlugin from '@ckeditor/ckeditor5-essentials/src/essentials';
import BoldPlugin from '@ckeditor/ckeditor5-basic-styles/src/bold';
import ItalicPlugin from '@ckeditor/ckeditor5-basic-styles/src/italic';
import UnderlinePlugin from '@ckeditor/ckeditor5-basic-styles/src/underline';
import AlignmentPlugin from '@ckeditor/ckeditor5-alignment/src/alignment';
import WordCount from "@ckeditor/ckeditor5-word-count/src/wordcount";

export default {
  props: ['value', 'placeholder', 'title', 'changeClick', 'maxCharacters'],
  data() {
    return {
      editor: ClassicEditor,
      errorMsg: '',
      editorData: this.value,
      editorConfig: {
        placeholder: this.placeholder,
        plugins: [
          ParagraphPlugin,
          EssentialsPlugin,
          BoldPlugin,
          ItalicPlugin,
          UnderlinePlugin,
          AlignmentPlugin,
          WordCount
        ],
        alignment: {
          options: ['left', 'center', 'right', 'justify']
        },
        toolbar: [
          'bold',
          'italic',
          'underline',
          'alignment:left',
          'alignment:center',
          'alignment:right',
          'alignment:justify',
        ],
        wordCount: {
          onUpdate: ({characters}) => {
            if (characters > this.maxCharacters) {
              this.errorMsg = 'Character limit cannot exceed '+this.maxCharacters;
            } else {
              this.errorMsg = '';
            }
          }
        }
      }
    }
  },
  methods: {
    handleInput(val) {
      this.editorData = val;
      this.$emit('changeClick')
      this.$emit('input', this.editorData)
    },
  }
}
</script>

<style scoped>
.vue-editor {
  margin-bottom: 15px;
  border: none !important;
}

.editor-with-title {
  border-radius: 4px;
  border: solid 1px #f3f3f3;
  background-color: #ffffff;
}

.editor-header {
  padding-top: 12px;
  padding-left: 15px;
  padding-bottom: 8px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #464646;
}

.error--text {
  padding: 0 10px;
  font-size: 13px;
}
</style>
