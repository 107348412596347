<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        persistent
        width="680"
    >
      <v-card>
        <product-add-dialog-form :key="productId" :pageId="pageId" :categoryId="categoryId" :changeDialogStatus="closeDialog"
                                 :paymentLinkOption="paymentLinkOption" :product="product"
                                 :productId="productId"></product-add-dialog-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ProductAddDialogForm from './ProductAddDialogForm.vue';


export default {
  name: "ProductAddDialog",
  components: {
    ProductAddDialogForm
  },

  data() {
    return {
      dialog: false,
      categoryId: null,
      productId: null,
      paymentLinkOption: "none",
      pageId: "",
      product: {
        name: "",
        price: "",
        isPopular: false,
        paymentLink: null,
        hyperLink: null,
        image: null,
      }
    }
  },
  methods: {
    closeDialog() {
      this.productId = null;
      this.categoryId = null;
      this.paymentLinkOption = "none";
      this.product = {
        name: "",
        price: "",
        isPopular: false,
        paymentLink: null,
        hyperLink: null,
        image: null,
      };
      this.dialog = false;
    },
    showUploadInfoDialog() {
      this.dialog = true;
    },
  },
  mounted() {
    this.$eventBus.$on('showProductAddDialog', (categoryId) => {
      this.pageId = this.$store.getters.getCustomPage.pageId;
      this.categoryId = categoryId;
      this.paymentLinkOption = "none";
      if (this.product.productId) {
        delete this.product.productId;
        delete this.product.productCategoryId;
      }
      this.showUploadInfoDialog();
    });
    this.$eventBus.$on('showProductEditDialog', (categoryId, productId) => {
      this.pageId = this.$store.getters.getCustomPage.pageId;
      this.categoryId = categoryId;
      this.productId = productId;
      const productToEdit = this.$store.getters.getCustomPage.products.find(({productId}) => productId === this.productId);
      this.product.name = productToEdit.name;
      this.product.price = productToEdit.price;
      this.product.isPopular = productToEdit.isPopular;
      this.product.paymentLink = productToEdit.paymentLink;
      this.product.hyperLink = productToEdit.hyperLink;
      this.product.image = productToEdit.image;
      
      if (this.product.paymentLink) {
        this.paymentLinkOption = productToEdit.paymentLink.match("paymentlink") ? "default" : "fixed";
      } else {
        this.paymentLinkOption = "none";
      }

      if (this.product.productId) {
        delete this.product.productId;
        delete this.product.productCategoryId;
      }
      this.showUploadInfoDialog();
    });
  }

}
</script>

<style>
</style>
