<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        persistent
        width="680"
    >
      <v-card>
        <cover-change-dialog-form :changeDialogStatus="closeDialog" :cover="cover"></cover-change-dialog-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CoverChangeDialogForm from './CoverChangeDialogForm.vue';

export default {
  name: "CoverChangeDialog",
  components: {
    CoverChangeDialogForm
  },

  data() {
    return {
      cover: null,
      dialog: false,
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    showDialog() {
      this.dialog = true;
    }
  },
  mounted() {
    this.$eventBus.$on('showUploadCoverDialog', () => {
      this.cover = this.$store.getters.getCustomPage.coverPhoto;
      this.showDialog();
    });
  }

}
</script>

<style>
</style>
