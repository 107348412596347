<template>
  <div style="background-color: white;" class="page__container">
    <title-change-dialog></title-change-dialog>
    <logo-change-dialog></logo-change-dialog>
    <cover-change-dialog></cover-change-dialog>
    <social-link-add-dialog></social-link-add-dialog>
    <category-add-dialog></category-add-dialog>
    <product-add-dialog></product-add-dialog>
    <product-delete-dialog></product-delete-dialog>
    <about-add-dialog></about-add-dialog>
    <email-add-dialog></email-add-dialog>
    <phone-add-dialog></phone-add-dialog>
    <website-add-dialog></website-add-dialog>
    <address-add-dialog></address-add-dialog>
    <page-url-change-dialog></page-url-change-dialog>
    <category-delete-dialog></category-delete-dialog>
    
    <div>
      <custom-page-container :customPage="customPage" :editMode="true" :customerPortalBasePath="customerPortalBasePath"></custom-page-container>
    </div>
    <div class="page-url simple-row">
      <span style="font-weight: 800; margin-right: 16px;">Page URL:</span> {{ pageBasePath }}
      <div class="url-fragment">{{ customPage.urlFragment }}</div>
      <v-btn
          color="primary"
          elevation="2"
          fab
          small
          @click="showChangeUrlDialog"
      >
        <v-icon
            dark
        >
          mdi-pencil
        </v-icon>
      </v-btn>
    </div>
    <div class="page-submit simple-row">
      Submit page for review
      <v-btn
          class="page-sumbit-btn"
          color="primary"
          rounded
          @click="submitPage()"
      >
        Submit
      </v-btn>
    </div>
  </div>
</template>

<script>
import CustomPageContainer from "cpp-js-util/components/common/CustomPageContainer";
import TitleChangeDialog from './TitleChangeDialog.vue';
import LogoChangeDialog from './LogoChangeDialog.vue';
import CoverChangeDialog from './CoverChangeDialog.vue';
import SocialLinkAddDialog from './SocialLinkAddDialog.vue';
import CategoryAddDialog from './CategoryAddDialog.vue';
import ProductAddDialog from './ProductAddDialog';
import AboutAddDialog from './AboutAddDialog.vue';
import EmailAddDialog from './EmailAddDialog.vue';
import PhoneAddDialog from './PhoneAddDialog.vue';
import WebsiteAddDialog from './WebsiteAddDialog.vue';
import AddressAddDialog from './AddressAddDialog.vue';
import ProductDeleteDialog from './ProductDeleteDialog.vue';
import PageUrlChangeDialog from './PageUrlChangeDialog.vue';
import CategoryDeleteDialog from './CategoryDeleteDialog.vue';

export default {
  name: "CustomPage",
  components: {
    CustomPageContainer,
    TitleChangeDialog,
    LogoChangeDialog,
    CoverChangeDialog,
    SocialLinkAddDialog,
    CategoryAddDialog,
    ProductAddDialog,
    AboutAddDialog,
    EmailAddDialog,
    PhoneAddDialog,
    WebsiteAddDialog,
    AddressAddDialog,
    ProductDeleteDialog,
    PageUrlChangeDialog,
    CategoryDeleteDialog,
  },
  data() {
    return {
      page: {
        pageId: "",
        title: "",
      }
    }
  },
  computed: {
    customPage() {
      return this.$store.getters.getCustomPage;
    },
    pageBasePath() {
      return this.$store.getters.getPageBasePath;
    },
    customerPortalBasePath() {
      return process.env.VUE_APP_CUSTOMER_PORTAL_URL;
    },
  },
  methods: {
    showChangeUrlDialog() {
      this.$eventBus.$emit('showPageUrlChangeDialog');
    },
    submitPage() {
      this.page.pageId = this.customPage.pageId;
      this.page.title = this.customPage.title;
      if(!this.customPage.contactPhone || !this.customPage.contactEmail) {
        this.$feedback.showErrorMessage("Please update contact email & phone number from your user profile!").then(() =>{
          this.$router.push({path: '/account-info'});
        });
        return;
      }
      this.$cppClient.post("page/submit", this.page)
          // eslint-disable-next-line no-unused-vars
          .then(res => {
            this.$feedback.showSuccessMessage('Custom Page submission was successful.')
            this.$router.push({path: '/payment-links'});
          }).catch(({response}) => {
        this.$logger.loggable(response).log();
        this.$feedback.showFailed(response);
      })
    }
  }
}
</script>

<style lang="scss" scoped>
$LaptopW: 1440px;
$MedW: 1024px;
$tabLS: 900px;
$mobileW: 750px;

.page__container{
  margin-top: 60px;
  @media screen and (min-width:1001px) {
    margin-top: 80px;
  }
}
.about-section {
  width: 100%;
  height: 485px;
  margin: 61px 0 5px;
  padding: 50px 136.3px 21px 96px;
  background-color: #f7f7f7;
}

.divider {
  border-right: 1px solid #e2136e;
}

.divider:last-child {
  border-right: 0
}

.another-amount {
  margin: 26px 0 5px;
  padding: 5px 136.3px 5px 96px;
  align-items: center;
  justify-content: center;
  display: grid;
}

.filter-primary {
  filter: invert(21%) sepia(75%) saturate(2325%) hue-rotate(311deg) brightness(100%) contrast(100%);
  width: 68px;
  margin-right: 51px;
}

.filter-primary:last-child {
  margin-right: 0;
}

.page-url {
  max-width: 1250px;
  height: 100px;
  // margin: 0 5px 1px 110px;
  margin-left: auto;
  margin-right: auto;
  font-family: Roboto;
  font-size: 32px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: normal;
  text-align: left;
  color: #464646;
  background-color: white;
  padding-left: 20px;
}

.page-submit {
  max-width: 1250px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  height: 100px;
  // margin: 0 5px 40px 110px;
  font-family: Roboto;
  font-size: 44px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.52;
  letter-spacing: normal;
  text-align: left;
  color: #464646;
  background-color: white;
}

.page-sumbit-btn {
  height: 50px;
  min-height: 50px;
  font-size: 36px;
  margin-left: 10px;
}

.url-fragment {
  border-style: solid;
  border-width: 2px;
  border-radius: 40px;
  max-width: 520px;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, .2), 0 2px 2px 0 rgba(0, 0, 0, .14), 0 1px 5px 0 rgba(0, 0, 0, .12);
  padding-left: 5px;
  padding-right: 10px;
  margin-right: 5px;
  font-size: 24px;
}

@media screen and (max-width: $mobileW) {
  .page-submit {
    font-size: 20px;
    margin: 0 0 20px 0;
    justify-content: center;
  }
  .page-sumbit-btn {
    height: 22px;
    font-size: 20px;
    margin-left: 5px;
  }
  .page-url {
    margin: 0;
    text-align: center;
    font-size: 10px;
  }
}
</style>
