<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        persistent
        width="680"
    >
      <v-card>
        <div class="banner_form">
          <span class="banner_form_title">
            Are you sure you want to delete this Category ?
          </span>
          <div class="banner_form_title" style="font-size: 18px; width: 400px;">
            Deleting will result in deletion of all the products under this category as well.
          </div>
          <v-row style="margin-bottom: 50px; margin-top: 25px">
            <span class="cancel-button" @click="closeDialog">Cancel</span>
            <v-btn color="#e2136e" style="color: #FFFFFF"
                   @click="deleteCategory">
              Delete
            </v-btn>
          </v-row>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: "CategoryDeleteDialog",
  components: {},
  data() {
    return {
      dialog: false,
      pageId: "",
      categoryId: null,
    }
  },
  methods: {
    closeDialog() {
      this.pageId = null;
      this.categoryId = null;
      this.dialog = false;
    },
    showUploadInfoDialog() {
      this.dialog = true;
    },
    deleteCategory() {
      this.$feedback.showLoading();

      this.$cppClient.post('/page/product-category/delete', {
        pageId: this.pageId,
        productCategoryId: this.categoryId
      })
          .then(() => {
            this.$cppClient.post('/page/preview', {
              pageId: this.pageId,
            }).then(res => {
              this.$feedback.hideLoading();
              this.$store.commit('setCustomPage', res.data);
            })
            .catch(({response}) => {
              this.$feedback.hideLoading();
              this.$feedback.showFailed(response);
            })
          })
          .catch(({response}) => {
            this.$feedback.hideLoading();
            this.$feedback.showFailed(response);
          })
          .finally(() => {
            this.closeDialog();
          })
    },
  },
  mounted() {
    this.$eventBus.$on('showCategoryDeleteDialog', (categoryId) => {
      this.categoryId = categoryId;
      this.pageId = this.$store.getters.getCustomPage.pageId;
      this.showUploadInfoDialog();
    });
  }
}
</script>

<style>
.item-layout-margin {
  margin-top: 50px
}

.item-div {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.banner_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.banner_form_title {
  padding-top: 20px;
  font-size: 22px;
  font-weight: normal;
  font-style: normal;
  text-align: center;
  color: #464646;
}

.banner-div {
  display: flex;
  width: 100%;
  height: 75px;
  border-radius: 4px;
  background-color: #ffffff;
  margin-top: 25px;
  margin-bottom: 20px;
}
</style>
