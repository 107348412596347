<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        persistent
        width="680"
    >
      <v-card>
        <phone-add-dialog-form :changeDialogStatus="closeDialog" :contactNumber="contactNumber"></phone-add-dialog-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import PhoneAddDialogForm from './PhoneAddDialogForm.vue';

export default {
  name: "PhoneAddDialog",
  components: {
    PhoneAddDialogForm,
  },

  data() {
    return {
      contactNumber: "",
      dialog: false,
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    showUploadInfoDialog() {
      this.dialog = true;
    }
  },
  mounted() {
    this.$eventBus.$on('showPhoneAddDialog', () => {
      this.contactNumber = this.$store.getters.getCustomPage.contactNumber;
      this.showUploadInfoDialog();
    });
  }

}
</script>

<style>
</style>
