<template>
  <v-form ref="form" v-model="isValid">
    <div class="web-url-form">
      <span class="form_title"
            style="margin-top: 60px; margin-bottom: 20px;">Add Contact Website</span>
      <v-text-field
          v-model="website"
          :rules="websiteUrlRules"
          counter
          dense
          label="Enter Website Url"
          maxlength="100"
          outlined
          required
          style="width: 80%"
      ></v-text-field>

      <v-row style="margin-bottom: 50px; margin-top: 25px">
        <span class="cancel-button" @click="clearForm">Cancel</span>
        <v-btn :disabled="!isValid" color="#e2136e" style="color: #FFFFFF"
               @click="updateWebsiteUrl">
          Update Website Url
        </v-btn>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import {URL_RULE} from "cpp-js-util/CppUtil";

export default {
  name: "WebsiteAddDialogForm",
  props: ['changeDialogStatus', 'website'],
  data() {
    return {
      isValid: false,
      formData: null,
      websiteUrlRules: [
        v => !!v || 'Website url is required',
        v => URL_RULE.test(v) || 'Provide valid url starting with https'
      ],
    }
  },
  mounted() {
    this.formData = new FormData();
    this.pageId = this.$store.getters.getCustomPage.pageId;
  },
  computed: {
    basePath() {
      return this.$store.getters.getPageBasePath;
    },
  },
  methods: {
    clearForm() {
      this.changeDialogStatus();
    },
    updateWebsiteUrl() {
      this.$feedback.showLoading();

      var formData = new FormData();
      formData.append("contactWebsite", this.website);
      formData.append("pageId", this.pageId);

      this.$cppClient.post('/page/update', formData, null, true)
          .then(res => {
            this.$feedback.hideLoading();
            this.$store.commit('setCustomPage', res.data);
          })
          .catch(({response}) => {
            this.$feedback.hideLoading();
            this.$feedback.showFailed(response);
          })
          .finally(() => {
            this.clearForm();
          })
    },
  }
}
</script>

<style>
.web-url-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form_title {
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.82;
  letter-spacing: normal;
  text-align: center;
  color: #464646;
}
</style>
