<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        persistent
        width="680"
    >
      <v-card>
        <social-link-add-dialog-form
            :changeDialogStatus="closeDialog"
            :facebookLink="facebookLink"
            :hello="whatsappLink"
            :instagramLink="instagramLink"
            :whatsappLink="whatsappLink"
            :youtubeLink="youtubeLink"
        ></social-link-add-dialog-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import SocialLinkAddDialogForm from './SocialLinkAddDialogForm.vue';

export default {
  name: "SocialLinkAddDialog",
  components: {
    SocialLinkAddDialogForm,
  },

  data() {
    return {
      socialLinks: [],
      pageId: "",
      facebookLink: "",
      whatsappLink: "",
      youtubeLink: "",
      instagramLink: "",
      dialog: false,
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    showUploadInfoDialog() {
      this.dialog = true;
    }
  },
  mounted() {
    this.$eventBus.$on('showAddSocialLinkDialog', () => {
      var links = this.$store.getters.getCustomPage.socialLinks ? this.$store.getters.getCustomPage.socialLinks : ",,,";

      this.socialLinks = links.split(',');

      this.facebookLink = this.socialLinks[0];
      this.whatsappLink = this.socialLinks[1];
      this.youtubeLink = this.socialLinks[2];
      this.instagramLink = this.socialLinks[3];
      this.showUploadInfoDialog();
    });
  }

}
</script>

<style>
</style>
