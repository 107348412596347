<template>
  <v-form ref="form" v-model="isValid">
    <div class="about_form">
      <span class="about_form_title"
            style="margin-top: 60px; margin-bottom: 20px;">Add About</span>

      <div class="about-upload-requirements">
        Character limit: 500
      </div>
      <div class="col-md-10" style="width: 100%; height: 50%;">
        <editor v-model="about" :maxCharacters="500" title="Add About Text"></editor>
      </div>

      <v-row style="margin-bottom: 50px; margin-top: 25px;">
        <span class="cancel-button" @click="clearForm">Cancel</span>
        <v-btn :disabled="isDisabled" color="#e2136e" style="color: #FFFFFF"
               @click="updateAboutText">
          Update About
        </v-btn>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import Editor from '../../common/Editor.vue';

export default {
  name: "AboutAddDialogForm",
  components: {
    Editor
  },
  props: ['changeDialogStatus', 'about'],
  data() {
    return {
      pageId: "",
      isValid: false,
      formData: null,
    }
  },
  mounted() {
    this.formData = new FormData();
    this.pageId = this.$store.getters.getCustomPage.pageId;
  },
  computed: {
    basePath() {
      return this.$store.getters.getPageBasePath;
    },
    isDisabled() {
      return !this.about || this.stripHtml(this.about).length > 500;
    },
  },
  methods: {
    clearForm() {
      this.$refs.form.reset();
      this.changeDialogStatus();
    },
    stripHtml(text) {
      return text
          .replace(/(<([^>]+)>)/ig, "")
          .replace(/</g, "&lt;")
          .replace(/>/g, "&gt;")
          .replace(/"/g, "&quot;")
          .replace(/'/g, "&#039;")
          .replace(/&nbsp;/g, '');
    },
    updateAboutText() {
      this.$feedback.showLoading();

      var formData = new FormData();
      formData.append("about", this.about);
      formData.append("pageId", this.pageId);

      this.$cppClient.post('/page/update', formData, null, true)
          .then(res => {
            this.$feedback.hideLoading();
            this.$store.commit('setCustomPage', res.data);
          })
          .catch(({response}) => {
            this.$feedback.hideLoading();
            this.$feedback.showFailed(response);
          })
          .finally(() => {
            this.clearForm();
          })
    },
  }
}
</script>

<style>
.about_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.about_form_title {
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.82;
  letter-spacing: normal;
  text-align: center;
  color: #464646;
}
.about-upload-requirements {
  color: #777777;
  text-align: center;
}
</style>
