<template>
  <v-form ref="form" v-model="isValid">
    <div class="page-title-form">
      <span class="form_title"
            style="margin-top: 60px; margin-bottom: 20px;">Custom Page Title</span>
      <v-text-field
          v-model="title"
          :rules="generalRules"
          counter
          dense
          label="Enter Title"
          maxlength="28"
          outlined
          required
          @keydown="nameKeydown($event)"
          style="width: 80%"
      ></v-text-field>

      <v-row style="margin-bottom: 50px; margin-top: 25px">
        <span class="cancel-button" @click="closeForm">Cancel</span>
        <v-btn :disabled="!isValid" color="#e2136e" style="color: #FFFFFF"
               @click="updateTitle">
          Update Title
        </v-btn>
      </v-row>
    </div>
  </v-form>
</template>

<script>

export default {
  name: "TitleChangeDialogForm",
  props: ['changeDialogStatus', 'title'],
  data() {
    return {
      pageId: "",
      isValid: false,
      inputRules: [
        v => !!v || 'Input is required',
      ],
      formData: null,
      generalRules: [
        v => !!v || 'Input is required',
        v => (v && v.length <= 30) || 'Page title cannot be more than 28 characters',
      ],
    }
  },
  mounted() {
    this.formData = new FormData();

    this.pageId = this.$store.getters.getCustomPage.pageId;
  },
  computed: {
    basePath() {
      return this.$store.getters.getPageBasePath;
    },
  },
  watch: {
    title(val) {
      let filteredTitle = val.replace(/[^a-zA-Z0-9 ]/g, "");

      this.$nextTick(() => {
        this.title = filteredTitle;
      })
    },
  },
  methods: {
    nameKeydown(e) {
      if (!/^[a-zA-Z0-9 ]*$/.test(e.key)) {
        e.preventDefault();
      }
    },
    closeForm() {
      this.changeDialogStatus();
    },
    updateTitle() {
      this.$feedback.showLoading();

      var formData = new FormData();
      formData.append("title", this.title);
      formData.append("pageId", this.pageId);

      this.$cppClient.post('/page/update', formData, null, true)
          .then(res => {
            this.$feedback.hideLoading();
            this.$store.commit('setCustomPage', res.data);
          })
          .catch(({response}) => {
            this.$feedback.hideLoading();
            this.$feedback.showFailed(response);
          })
          .finally(() => {
            this.changeDialogStatus();
          })
    },
  }
}
</script>

<style>
.page-title-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form_title {
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.82;
  letter-spacing: normal;
  text-align: center;
  color: #464646;
}
</style>
