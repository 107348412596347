<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        persistent
        width="680"
    >
      <v-card>
        <category-add-dialog-form :addedCategories="addedCategories" :categoryList="categoryList"
                                  :changeDialogStatus="closeDialog"></category-add-dialog-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CategoryAddDialogForm from './CategoryAddDialogForm.vue';

export default {
  name: "CategoryAddDialog",
  components: {
    CategoryAddDialogForm,
  },

  data() {
    return {
      dialog: false,
      addedCategories: [],
      categoryList: [],
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    showUploadInfoDialog() {
      this.dialog = true;
    },
    async getCategoryList() {
      this.$feedback.showLoading();
      try {
        let {data} = await this.$cppClient.post('/page/list/product/categories', {})
        this.categoryList = data.productCategoryList;

        if (this.addedCategories) {
          this.categoryList = this.categoryList.filter(a => !this.addedCategories.map(b => b.productCategoryId).includes(a.productCategoryId));
        }
        this.$feedback.hideLoading();
      } catch({response}) {
        this.$feedback.hideLoading();
        this.$feedback.showFailed(response);
      }
    },
  },
  mounted() {
    this.$eventBus.$on('showCategoryAddDialog', () => {
      this.addedCategories = this.$store.getters.getCustomPage.productCategories;
      this.getCategoryList();
      this.showUploadInfoDialog();
    });
  }

}
</script>

<style>
</style>
