<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        persistent
        width="680"
    >
      <v-card>
        <website-add-dialog-form :changeDialogStatus="closeDialog" :website="website"></website-add-dialog-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import WebsiteAddDialogForm from './WebsiteAddDialogForm.vue';

export default {
  name: "WebsiteAddDialog",
  components: {
    WebsiteAddDialogForm,
  },

  data() {
    return {
      website: "",
      dialog: false,
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    showUploadInfoDialog() {
      this.dialog = true;
    }
  },
  mounted() {
    this.$eventBus.$on('showWebsiteAddDialog', () => {
      this.website = this.$store.getters.getCustomPage.contactWebsite;
      this.showUploadInfoDialog();
    });
  }

}
</script>

<style>
</style>
