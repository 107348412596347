<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        persistent
        width="680"
    >
      <v-card>
        <email-add-dialog-form :changeDialogStatus="closeDialog" :email="email"></email-add-dialog-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EmailAddDialogForm from './EmailAddDialogForm.vue';

export default {
  name: "EmailAddDialog",
  components: {
    EmailAddDialogForm,
  },

  data() {
    return {
      email: "",
      dialog: false,
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    showUploadInfoDialog() {
      this.dialog = true;
    }
  },
  mounted() {
    this.$eventBus.$on('showEmailAddDialog', () => {
      this.email = this.$store.getters.getCustomPage.contactEmail;
      this.showUploadInfoDialog();
    });
  }

}
</script>

<style>
</style>
