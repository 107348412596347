<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        persistent
        width="680"
    >
      <v-card>
        <logo-change-dialog-form :changeDialogStatus="closeDialog" :logo="logo"></logo-change-dialog-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import LogoChangeDialogForm from './LogoChangeDialogForm.vue';

export default {
  name: "LogoChangeDialog",
  components: {
    LogoChangeDialogForm
  },

  data() {
    return {
      logo: null,
      dialog: false,
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    showDialog() {
      this.dialog = true;
    }
  },
  mounted() {
    this.$eventBus.$on('showUploadLogoDialog', () => {
      this.logo = this.$store.getters.getCustomPage.logo;
      this.showDialog();
    });
  }

}
</script>

<style>
</style>
