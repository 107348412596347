<template>
  <div>
    <v-container style="max-width: 90%">
      <v-row
          align="center">
        <v-stepper v-model="page" value="page" >
          <v-stepper-content v-for="(step,n) in pages"
                             :key="`${n}-${category}`"
                             :step="n+1">
            <v-row justify="center">
              <v-col :cols="colValue"
                     v-for="(product, i) in productList.slice(0 + (4*n), 4 + (4*n))"
                     :key="i">
                <product-card :editMode="editMode" :product='product' :socialLinks="socialLinks"></product-card>
              </v-col>
            </v-row>
          </v-stepper-content>
        </v-stepper>
      </v-row>
    </v-container>
    <div v-if="this.productList.length > 0" style="text-align: center;">
      <v-pagination
          v-model="page"
          :length="pages"
          circle
      ></v-pagination>
    </div>
  </div>
</template>

<script>
import ProductCard from './ProductCard.vue'

export default {
  components: { ProductCard },
  name: "ProductList",
  props: ['editMode', 'category','productList','socialLinks'],
  data () {
    return {
      page: 0,
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.page = 1;
    });
    this.$eventBus.$on('productAdded', () => {
      this.$forceUpdate();
    })
  },
  computed: {
    colValue() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 6
        case 'sm':
          return 6
        case 'md':
          return 3
        case 'lg':
          return 3
        case 'xl':
          return 3
      }
    },
    pages() {
      var pages = Math.ceil(this.productList.length/4);
      if(pages == 0) pages = 1;
      if(this.page > pages) this.page--;
      return pages;
    }
  },
  methods: {
  },
}
</script>

<style scoped>
.preview-layout {
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.product-slide-container {
  flex-direction: row;
  display: flex;
  margin-left: auto;
  margin-right: auto;
}

.v-stepper {
  background: none;
  box-shadow: none;
  width: 100%;
}

.v-stepper__content {
  padding: 0;
}
</style>
