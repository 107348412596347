<template>
  <v-form ref="form" v-model="isValid">
    <div class="banner_form">
      <span
        class="banner_form_title"
        style="margin-top: 60px; margin-bottom: 40px;"
        >{{ productId ? "Update Product" : "Add Product" }}</span
      >
      <div v-if="productImage.image || product.image">
        <v-img
          :src="url || product.image"
          style="width: 150px; height: 150px"
        ></v-img>
      </div>
      <div class="error_div" v-if="imageTypeError">
        <span class="size_error_text">
          Image format not supported.
        </span>
      </div>
      <div class="error_div" v-if="imageDimensionError">
        <span class="size_error_text">
          Image dimension less than 300x300 px.
        </span>
      </div>
      <div class="error_div" v-if="imageSizeError">
        <span class="size_error_text">
          Image size needs to be less than 500KB.
        </span>
      </div>
      <v-file-input
        id="uploadProductImage"
        ref="uploadProductImage"
        v-model="productImage.image"
        :required="product.image"
        accept="image/png, image/jpg"
        class="d-none"
        show-size
        style="color: #e2136e; margin: 0px; padding: 0px;"
        @change="previewImage"
      >
      </v-file-input>
      <div
        class="button-container"
        style="margin-top: 25px; margin-bottom: 10px;"
      >
        <v-btn
          @click="manuallyOpenFileSelector"
          color="#f3f3f3"
          style="color: #e2136e; padding: 0px 24px;"
          elevation="0"
        >
          <v-icon style="margin-right: 8px">mdi-attachment</v-icon>
          Upload Product Photo (PNG, JPG)
        </v-btn>
      </div>
      <div class="product-image-requirements">
        Product Image must meet the following requirements:<br />
        Preferable Dimension: 300x340 pixel<br />
        Preferable Aspect Ratio: 15:17<br />
        Format: .png or .jpg<br />
        Size: Within 500KB
      </div>
      <div class="product-image-warning-note">
        Uploading any inappropriate image will result in immediate revoke.
      </div>
      <v-text-field
        v-model="product.name"
        :rules="[inputRules.required]"
        counter
        dense
        label="Enter Product Name"
        maxlength="20"
        outlined
        required
        style="width: 80%"
      ></v-text-field>
      <v-text-field
        v-model="product.price"
        :rules="[
          inputRules.required,
          inputRules.minAmount,
          inputRules.maxAmount,
        ]"
        counter
        dense
        label="Enter Product Price"
        maxlength="6"
        outlined
        required
        style="width: 80%"
        type="number"
      ></v-text-field>
      <v-checkbox
        v-model="product.isPopular"
        label="Add as popular product"
      ></v-checkbox>
      <div
        style="border: 1px solid #e2136e; width: 90%; box-shadow: 0px 1px 6px #888888; margin-bottom: 10px"
      ></div>

      <template v-if="paymentLinkOption === 'fixed'">
        <v-radio-group
          v-model="link.linkExpiryType"
          row
          class="expiration__container"
        >
          <v-radio value="lifetime" label="Lifetime" class="options"></v-radio>
          <v-radio value="custom" label="Custom" class="options"></v-radio>
        </v-radio-group>

        <div
          v-if="link.linkExpiryType === 'custom'"
          class="date-picker__container"
          :class="{ mb__2: !validDatetime, mb__10: validDatetime }"
        >
          <date-picker
            class="date-picker__container--item"
            v-model="link.linkExpiryDate"
            :disabled-date="
              (date) => date < new Date(new Date().valueOf() - 1000 * 3600 * 24)
            "
            placeholder="Select a date & time"
            type="datetime"
            valueType="format"
            :open.sync="open"
            @change="handleChange"
          >
          </date-picker>
        </div>

        <v-row>
          <div class="error__div">
            <span v-if="!validDatetime">Please select a future time.</span>
          </div>
        </v-row>

        <template
          class="reference__container"
          v-for="(reference, index) in link.references"
        >
          <span :key="index" style="width: 80% !important;">
            <v-text-field
              v-model="reference.fieldName"
              label="Additional Field Name"
              class="reference__container--item"
              outlined
              placeholder="Enter Additional Field Name"
              dense
              :rules="inputRules.referenceFieldRules"
              type="text"
              required
            ></v-text-field>
            <span class="opinion__container">
              <v-checkbox
                label="Mandatory field"
                color="primary"
                v-model="reference.required"
              ></v-checkbox>
              <v-icon color="primary" @click="removeReferenceField(index)">
                mdi-close
              </v-icon>
            </span>
          </span>
        </template>

        <v-btn
          class="btn__add"
          color="#EFEFEF"
          @click="addReferenceField"
          :disabled="checkNumberOfReference"
        >
          <v-icon dark left size="14" class="plus__icon">mdi-plus</v-icon>
          Add Another Field
        </v-btn>
      </template>

      <div class="payment-link-option-container">
        <v-radio-group v-model="paymentLinkOption" row mandatory>
          <v-radio
            label="None"
            value="none"
            class="options"
            @click="setNoPaymentLink"
          ></v-radio>
          <v-radio
            label="Default Payment"
            value="default"
            class="options"
            @click="setDefaultPaymentLink"
          ></v-radio>
          <v-radio
            label="Fixed Payment"
            value="fixed"
            class="options"
            @click="setFixedPaymentLink"
          ></v-radio>
        </v-radio-group>
      </div>

      <v-btn
        v-if="paymentLinkOption === 'fixed'"
        :disabled="
          product.name.length === 0 ||
            product.price.length === 0 ||
            !validDatetime ||
            !validateInput()
        "
        color="#e2136e"
        style="color: #FFFFFF; margin-bottom: 20px;"
        @click="generatePaymentLink"
      >
        Generate PaymentLink
      </v-btn>
      <v-text-field
        v-if="paymentLinkOption !== 'none'"
        v-model="product.paymentLink"
        :rules="[inputRules.required]"
        counter
        required
        dense
        disabled
        label="Product Payment Link"
        maxlength="100"
        outlined
        style="width: 80%"
      ></v-text-field>
      <div
        style="border: 1px solid #e2136e; width: 90%; box-shadow: 0px 1px 6px #888888; margin-bottom: 20px;"
      ></div>
      <v-text-field
        v-model="product.hyperLink"
        counter
        dense
        label="Enter Product Hyperlink"
        maxlength="100"
        outlined
        style="width: 80%"
      ></v-text-field>

      <v-row style="margin-bottom: 50px; margin-top: 25px">
        <span class="cancel-button" @click="clearForm">Cancel</span>
        <v-btn
          :disabled="!isValid || isDisabled || !validDatetime"
          color="#e2136e"
          style="color: #FFFFFF"
          @click="uploadProduct"
        >
          {{ productId ? "Update product" : "Add product" }}
        </v-btn>
      </v-row>
    </div>
  </v-form>
</template>

<script>
export default {
  name: "ProductAddDialogForm",
  props: [
    "changeDialogStatus",
    "pageId",
    "categoryId",
    "productId",
    "product",
    "paymentLinkOption",
  ],
  data() {
    return {
      open: false,
      productImage: {
        image: null,
        extension: "",
        mimeType: "",
        pageImageType: "PRODUCT",
      },
      maxNumberOfReferences: 10,
      link: {
        linkExpiryType: "lifetime",
        linkExpiryDate: null,
        references: [],
      },
      imageSize: 0,
      imageHeight: 0,
      imageWidth: 0,
      fixedPaymentLink: null,
      isValid: false,
      inputRules: {
        required: (v) => !!v || "Value is required",
        maxAmount: (v) =>
          (v && v.length < 6) || "Amount must be less than 6 digits",
        minAmount: (v) => v > 0 || "Amount must be greater than 0",
        referenceFieldRules: [
          (v) => !!v || "Field name is required",
          (v) =>
            !v ||
            v.length <= 20 ||
            "Field name must not have more than 20 characters",
        ],
      },
    };
  },
  mounted() {
    if (this.paymentLinkOption === "fixed")
      this.fixedPaymentLink = this.product.paymentLink;
  },
  computed: {
    basePath() {
      return this.$store.getters.getPageBasePath;
    },
    isDisabled() {
      if (
        this.productImage.image === undefined ||
        this.productImage.image === null
      )
        return false;

      return (
        this.imageSize > 500000 ||
        this.imageHeight < 300 ||
        this.imageWidth < 300 ||
        (this.productImage.extension !== "png" &&
          this.productImage.extension !== "jpg" &&
          this.productImage.extension !== "jpeg")
      );
    },
    imageTypeError() {
      return (
        this.productImage.extension !== "png" &&
        this.productImage.extension !== "" &&
        this.productImage.extension !== "jpg" &&
        this.productImage.extension !== "jpeg"
      );
    },
    imageDimensionError() {
      return (
        this.imageSize != 0 && (this.imageHeight < 300 || this.imageWidth < 300)
      );
    },
    imageSizeError() {
      return this.imageSize > 500000;
    },
    validDatetime() {
      let current = new Date();
      let selected = new Date(this.link.linkExpiryDate);
      return !(this.link.linkExpiryType === "custom" && selected < current);
    },
    checkNumberOfReference() {
      return this.link.references.length === this.maxNumberOfReferences;
    },
  },
  methods: {
    validateInput() {
      if (this.link.references.length > 0) {
        for (const reference of this.link.references) {
          if (!reference.fieldName) {
            return false;
          }
          if (reference.fieldName.length > 20) {
            return false;
          }
        }
      }
      return true;
    },
    setNoPaymentLink() {
      this.product.paymentLink = "";
    },
    setDefaultPaymentLink() {
      this.product.paymentLink = this.$store.getters.getPaymentLink;
    },
    setFixedPaymentLink() {
      this.product.paymentLink = this.fixedPaymentLink
        ? this.fixedPaymentLink
        : null;
    },
    clearForm() {
      this.$refs.form.reset();
      this.productImage = {
        image: null,
        extension: "",
        mimeType: "",
        pageImageType: "PRODUCT",
      };
      (this.imageSize = 0),
        (this.imageHeight = 0),
        (this.imageWidth = 0),
        (this.fixedPaymentLink = null),
        (this.isValid = false),
        this.changeDialogStatus();
      this.link = {
        linkExpiryType: "lifetime",
        linkExpiryDate: null,
        references: [],
      };
    },
    resetFixedPaymentForm() {
      this.$refs.form.resetValidation();
      this.link = {
        linkExpiryType: "lifetime",
        linkExpiryDate: null,
        references: [],
      };
    },
    fireFileUploadEvent() {
      document.getElementById("uploadProductImage").click();
    },
    manuallyOpenFileSelector() {
      let el = this.$refs.uploadProductImage.$refs.input;
      el.click();
    },
    generatePaymentLink() {
      const prefix = process.env.VUE_APP_CUSTOMER_PORTAL_URL;
      var formData = new FormData();

      // let linkData = {
      //   description: this.product.name,
      //   amount: this.product.price,
      //   linkExpiryType: this.link.linkExpiryType,
      //   linkExpiryDate: this.link.linkExpiryDate,
      //   references: this.link.references,
      // };

      formData.append("productName", this.product.name);
      formData.append("amount", this.product.price);
      formData.append("linkExpiryType", this.link.linkExpiryType);
      formData.append("linkExpiryDate", this.link.linkExpiryDate);
      formData.append("references", JSON.stringify(this.link.references));

      this.$feedback.showLoading();
      this.$cppClient
        .post("payment-link/create", formData, null, true)
        .then(({ data }) => {
          this.$feedback.hideLoading();
          this.resetFixedPaymentForm();
          this.fixedPaymentLink = prefix + data.urlPath;
          this.product.paymentLink = this.fixedPaymentLink;
        })
        .catch(({ response }) => {
          this.$feedback.showFailed(response);
        });
    },
    async uploadProduct() {
      this.$feedback.showLoading();
      var formData = new FormData();
      if (this.product.image) {
        delete this.product.image;
      }
      if (this.productImage.image) {
        formData.append("image", this.productImage.image);
        formData.append("fileExtension", this.productImage.extension);
        formData.append("mimeType", this.productImage.mimeType);
        formData.append("pageImageType", this.productImage.pageImageType);
      }
      if (this.productId) this.product.productId = this.productId;

      this.product.productCategoryId = this.categoryId;

      formData.append("pageId", this.pageId);
      formData.append("product", JSON.stringify(this.product));
      try {
        let res = await this.$cppClient.post(
          "/page/update",
          formData,
          null,
          true
        );
        await this.$store.commit("setCustomPage", res.data);
        this.$eventBus.$emit("productAdded");
        this.$feedback.hideLoading();
      } catch ({ response }) {
        this.$feedback.hideLoading();
        this.$feedback.showFailed(response);
      } finally {
        this.clearForm();
      }
    },
    previewImage() {
      if (this.productImage.image !== undefined) {
        this.imageSize = this.productImage.image.size;
        this.productImage.mimeType = this.productImage.image.type;
        this.productImage.extension = this.productImage.image.name
          .split(".")
          .pop()
          .toLowerCase();

        this.url = URL.createObjectURL(this.productImage.image);

        let image = new Image();
        image.src = URL.createObjectURL(event.target.files[0]);

        let app = this;
        //Validate the File Height and Width.
        image.onload = function() {
          app.imageHeight = this.height;
          app.imageWidth = this.width;
        };

        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          this.publishableAsset.extension = this.asset.extension;
          this.publishableAsset.image = reader.result.split(",").pop();
          this.publishableAsset.mimeType = this.asset.mimeType;
        };
      }
    },
    handleChange(value, type) {
      if (type === "hour") {
        this.open = false;
      }
    },
    addReferenceField() {
      this.link.references.push({
        fieldName: "",
        required: false,
        value: "",
      });
    },
    removeReferenceField(index) {
      this.link.references.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.merchant-base-link {
  display: flex;
  flex-direction: row;
  width: 80%;
}

.merchant-url-layout {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  display: flex;
  height: 40px;
  border: solid 1px #dddddd;
  background-color: #dddddd;
  align-items: center;
}

.merchant-url-text {
  margin-left: 6px;
  margin-right: 3px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #9a9a9a;
  white-space: nowrap;
}

.banner_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.banner_form_title {
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.82;
  letter-spacing: normal;
  text-align: center;
  color: #464646;
}

.banner-image-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  border: solid 1px #f3f3f3;
  background-color: #f3f3f3;
}

.error_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5px;
}

.size_error_text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  padding-right: 15px;
  padding-left: 15px;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #d0021b;
}

.product-image-requirements {
  color: #777777;
  text-align: center;
}

.product-image-warning-note {
  text-align: center;
  font-size: 15px;
  color: #aaaaaa;
  padding: 30px 10px;
}

.expiration__container {
  height: 35px !important;
  margin-top: 0;
  padding-top: 0;

  @media (max-width: 480px) {
    width: 80%;
    height: 64px !important;
  }
}
.error__div {
  margin-bottom: 10px;
  font-size: 12px;

  span {
    color: #b71c1c !important;
    padding-left: 10px;
  }
}

.mb__2 {
  margin-bottom: 2px;
}

.mb__10 {
  margin-bottom: 10px;
}

.reference__container {
  display: flex;
  align-items: center;

  &--item {
    height: 59px !important;
    margin-bottom: 0 !important;
  }
}

.opinion__container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 40px !important;

  .v-input--selection-controls {
    margin-top: 0;
    padding-top: 0;
  }

  .v-input--selection-controls__input .v-icon {
    /* color: #ffcb15 !important; */
  }
}

.btn__add {
  color: #e2136e !important;
  width: 200px !important;
  margin-bottom: 5px;

  .plus__icon {
    margin-right: 0;
  }

  &:active {
    color: #ffffff !important;
    background-color: #e2136e !important;
  }
}

.v-btn--contained {
  box-shadow: none !important;
}

.asterisk {
  color: #e2136e;
}

.input-field {
  margin-bottom: 10px;
}

@media (max-width: 480px) {
  .payment-link-option-container {
    width: 80%;
  }
  .options {
    width: 100%;
  }
}
</style>
