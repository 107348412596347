<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        persistent
        width="680"
    >
      <v-card>
        <v-form ref="form" v-model="isValid">
          <div class="page-url-form">
					<span class="form_title"
                style="margin-top: 60px; margin-bottom: 20px;">Update Page Url</span>
            <div class="merchant-base-link">
              <div class="merchant-url-layout">
                <span class="merchant-url-text">{{ pageBasePath }}</span>
              </div>
              <v-text-field
                  v-model="urlFragment"
                  :disabled="this.$store.getters.isUpdate"
                  :rules="basePathRules"
                  counter
                  dense
                  label="Page Link"
                  outlined
                  placeholder="Link"
                  maxlength="24"
                  required
                  type="text">
              </v-text-field>
            </div>

            <v-row style="margin-bottom: 50px; margin-top: 25px">
              <span class="cancel-button" @click="closeDialog">Cancel</span>
              <v-btn :disabled="!isValid" color="#e2136e" style="color: #FFFFFF"
                     @click="updateUrl">
                Update
              </v-btn>
            </v-row>
          </div>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {URL_BASE_PATH_RULE} from "cpp-js-util/CppUtil";

export default {
  name: "PageUrlChangeDialog",
  components: {},

  data() {
    return {
      dialog: false,
      urlFragment: "",
      pageId: "",
      isValid: false,
      basePathRules: [
        v => !!v || 'Page path is required',
        v => (v && v.length <= 24) || 'Page path cannot be more than 24 characters',
        v => URL_BASE_PATH_RULE.test(v) || 'Provide valid character only'
      ],
    }
  },
  methods: {
    closeDialog() {
      this.$refs.form.reset();
      this.dialog = false;
    },
    showUploadInfoDialog() {
      this.dialog = true;
    },
    updateUrl() {
      this.$feedback.showLoading();

      var formData = new FormData();
      formData.append("urlFragment", this.urlFragment);
      formData.append("pageId", this.pageId);

      this.$cppClient.post('/page/update', formData, null, true)
          .then(res => {
            this.$feedback.hideLoading();
            this.$store.commit('setCustomPage', res.data);
          })
          .catch(({response}) => {
            this.$feedback.hideLoading();
            this.$feedback.showFailed(response);
          })
          .finally(() => {
            this.closeDialog();
          })
    },
  },
  mounted() {
    this.pageId = this.$store.getters.getCustomPage.pageId;
    this.$eventBus.$on('showPageUrlChangeDialog', () => {
      this.urlFragment = this.$store.getters.getCustomPage.urlFragment;

      this.showUploadInfoDialog();
    });
  },
  computed: {
    pageBasePath() {
      return this.$store.getters.getPageBasePath;
    },
  }
}
</script>

<style>
.page-url-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form_title {
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.82;
  letter-spacing: normal;
  text-align: center;
  color: #464646;
}
</style>
