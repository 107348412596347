<template>
  <v-form ref="form" v-model="isValid">
    <div class="social-link-form">
      <span class="form_title"
            style="margin-top: 60px; margin-bottom: 20px;">Add Social Links</span>
      <v-text-field
          v-model="facebookLink"
          :rules="urlRules"
          counter
          dense
          label="Enter Facebook Link"
          maxlength="200"
          outlined
          style="width: 80%; margin-bottom: 15px;"
      ></v-text-field>
      <v-text-field
          v-model="whatsappLink"
          :rules="urlRules"
          counter
          dense
          label="Enter Whatsapp Link"
          maxlength="200"
          outlined
          style="width: 80%; margin-bottom: 15px;"
      ></v-text-field>
      <v-text-field
          v-model="youtubeLink"
          :rules="urlRules"
          counter
          dense
          label="Enter Youtube Link"
          maxlength="200"
          outlined
          style="width: 80%; margin-bottom: 15px;"
      ></v-text-field>
      <v-text-field
          v-model="instagramLink"
          :rules="urlRules"
          counter
          dense
          label="Enter Instagram Link"
          maxlength="200"
          outlined
          style="width: 80%; margin-bottom: 15px;"
      ></v-text-field>

      <v-row style="margin-bottom: 50px; margin-top: 25px">
        <span class="cancel-button" @click="closeForm">Cancel</span>
        <v-btn :disabled="!isValid" color="#e2136e" style="color: #FFFFFF"
               @click="AddSocialLinks">
          Update Social Links
        </v-btn>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import {URL_RULE} from "cpp-js-util/CppUtil";

export default {
  name: "SocialLinkAddDialogForm",
  props: ['changeDialogStatus', 'facebookLink', 'whatsappLink', 'youtubeLink', 'instagramLink'],
  data() {
    return {
      page: {
        socialLinks: "",
        pageId: "",
      },
      isValid: false,
      formData: null,
      urlRules: [
        v => (v && v.length > 0 && !URL_RULE.test(v)) ? 'Provide valid url starting with https' : true
      ],
    }
  },
  mounted() {
    this.formData = new FormData();
    this.page.pageId = this.$store.getters.getCustomPage.pageId;
  },
  computed: {
    basePath() {
      return this.$store.getters.getPageBasePath;
    },
  },
  methods: {
    closeForm() {
      this.changeDialogStatus();
    },
    AddSocialLinks() {
      this.$feedback.showLoading();

      var formData = new FormData();
      var socialLinks = this.facebookLink + "," + this.whatsappLink + "," + this.youtubeLink + "," + this.instagramLink;
      formData.append("socialLinks", socialLinks);
      formData.append("pageId", this.page.pageId);
      this.$cppClient.post('/page/update', formData, null, true)
          .then(res => {
            this.$feedback.hideLoading();
            this.$store.commit('setCustomPage', res.data);
          })
          .catch(({response}) => {
            this.$feedback.hideLoading();
            this.$feedback.showFailed(response);
          })
          .finally(() => {
            this.changeDialogStatus();
          })
    },
  }
}
</script>

<style>
.social-link-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form_title {
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.82;
  letter-spacing: normal;
  text-align: center;
  color: #464646;
}
</style>
