<template>
  <div class="header-layout">
    <div>
      <v-img
          :lazy-src="require('./img/custom-page-cover-default.png')"
          :src="coverPic"
          class="cover-pic"
          max-height="640"
          max-width="100%"
          style="box-shadow: 0px 5px 5px grey;"
      >
      </v-img>
      <div>
        <v-btn
            v-if="editMode"
            absolute
            class="cover-button"
            color="primary" right
            rounded
            @click="showUploadCoverDialog"
        >
          <v-icon
              dark
          >
            mdi-camera
          </v-icon>
          <span class="cover_button-text">Add Cover</span>
        </v-btn>
      </div>
    </div>
    <div class="header-logo-container">
      <custom-page-logo :editMode="editMode" :logo="logo"></custom-page-logo>
    </div>
    <div style="margin-left: auto; margin-right: auto; align-content: center;">
      <div class="page-title">
        {{ title }}
        <v-btn
            v-if="editMode && title.length > 0"
            color="primary"
            elevation="2"
            fab
            small
            @click="showChangeTitleDialog"
        >
          <v-icon
              dark
          >
            mdi-pencil
          </v-icon>
        </v-btn>
      </div>
      <div v-if="editMode && !title" class="page-title-edit">
        <v-btn
            color="primary"
            elevation="2"
            fab
            small
            @click="showChangeTitleDialog"
        >
          <v-icon
              dark
          >
            mdi-plus
          </v-icon>
        </v-btn>
        Add Merchant Name
      </div>
    </div>
  </div>
</template>

<script>

import CustomPageLogo from "./CustomPageLogo";

export default {
  name: "CustomPageHeader",
  components: {CustomPageLogo},
  props: ['editMode', 'logo', 'title', 'cover'],
  data() {
    return {
      // logo: "https://cdn.vuetifyjs.com/images/profiles/marcus.jpg",
    }
  },
  mounted() {
    // if (this.customPageHeaderDetails.logo !== null)
    //   this.logo = this.customPageHeaderDetails.logo;
  },
  computed: {
    coverPic() {
      if (this.cover) return this.cover;
      return require('./img/custom-page-cover-default.png');
    },
    logoPic() {
      if (this.logo) return this.logo;
      return "https://sit-cpp-images.s3-ap-southeast-1.amazonaws.com/news-letter/1bc6df3d-546b-48e7-b070-f68ee7b57218.png"
    }
  },
  methods: {
    showUploadLogoDialog() {
      this.$eventBus.$emit('showUploadLogoDialog');
    },
    showUploadCoverDialog() {
      this.$eventBus.$emit('showUploadCoverDialog');
    },
    showChangeTitleDialog() {
      this.$eventBus.$emit('showChangeTitleDialog');
    },
  },

}
</script>

<style lang="scss" scoped>
$LaptopW: 1440px;
$MedW: 1024px;
$tabLS: 900px;
$mobileW: 750px;

.header-layout {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
}

.cover-pic {
  width: 100%;
  height: 462px;
  top: 0;
  left: 0;
}

.cover-button {
  z-index: 3;
  margin-top: -50px;
}

.header-logo-container {
  margin-left: auto;
  margin-right: auto;
  align-content: center;
  margin-top: -150px;
}

.logo-pic {
  z-index: 1;
  margin-top: 475px;
  margin-left: 42%;
}

.logo-button {
  z-index: 3;
  position: absolute;
  left: 56%;
  top: 694px;
}

.page-title {
  width: 100%;
  height: 74px;
  text-align: center;
  font-family: Roboto;
  font-size: 56px;
  font-weight: bold;
}

.page-title-edit {
  width: 100%;
  height: 40px;
  text-align: center;
  font-family: Roboto;
  font-size: 40px;
  font-weight: bold;
}

@media screen and (max-width: $mobileW) {
  .cover-pic {
    height: 170px;
  }
  .cover_button-text {
    display: none;
  }
  .header-logo-container {
    margin-top: -94px;
  }
  .page-title {
    font-size: 36px;
  }
  .page-title-edit {
    font-size: 20px;
  }
}
</style>
