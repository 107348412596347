<template>
  <v-form ref="form" v-model="isValid">
    <div class="banner_form">
      <span class="banner_form_title"
            style="margin-top: 60px; margin-bottom: 40px;">Custom Page Logo</span>
      <div v-if="page.image || logo">
        <v-img :src="url || logo" style="width: 150px; height: 150px"></v-img>
      </div>
      <div class="error_div" v-if="logoTypeError">
        <span
            class="size_error_text">
          Image format not supported.
        </span>
      </div>
      <div class="error_div" v-if="logoDimensionError">
        <span
            class="size_error_text">
          Image dimension less than 250x250 px.
        </span>
      </div>
      <div class="error_div" v-if="logoSizeError">
        <span
            class="size_error_text">
          Image size needs to be less than 500KB.
        </span>
      </div>
      <v-file-input
        id="uploadLogo"
        ref="uploadLogo"
        v-model="page.image"
        :rules="inputRules"
        accept="image/png, image/jpg"
        class="d-none"
        required
        @change="previewImage">
      </v-file-input>
      <div class="button-container" style="margin-top: 25px;">
        <v-btn @click="manuallyOpenFileSelector"
               color="#f3f3f3" style="color: #e2136e; padding: 0px 24px;" elevation="0">
          <v-icon style="margin-right: 8px">mdi-attachment</v-icon>
          Upload Photo (PNG, JPG)
        </v-btn>
      </div>

      <v-row style="margin-bottom: 50px; margin-top: 25px">
        <span class="cancel-button" @click="clearForm">Cancel</span>
        <v-btn :disabled="isDisabled" color="#e2136e" style="color: #FFFFFF"
               @click="uploadImage">
          Submit
        </v-btn>
      </v-row>
    </div>
    <div class="logo-upload-requirements">
      Page logo must meet the following requirements:<br/>
      Preferable Dimension: 300x300 px<br/>
      Preferable Aspect Ratio: 1:1<br/>
      Format: .png or .jpg<br/>
      Size: Within 500KB
    </div>
    <div class="logo-upload-warning-note">
      Uploading any inappropriate image will result in immediate revoke.
    </div>
  </v-form>
</template>

<script>

export default {
  name: "LogoChangeDialogForm",
  props: ['changeDialogStatus', 'logo'],
  data() {
    return {
      page: {
        image: null,
        extension: "",
        mimeType: "",
        pageImageType: "LOGO",
        pageId: "",
      },
      imageSize: 0,
      imageHeight: 0,
      imageWidth: 0,
      isValid: false,
      url: null,
      inputRules: [
        v => !!v || 'Input is required',
      ],
      formData: null,
    }
  },
  mounted() {
    this.formData = new FormData();
  },
  computed: {
    basePath() {
      return this.$store.getters.getPageBasePath;
    },
    isDisabled() {
      return !this.page.image || this.imageSize > 500000 || this.imageHeight < 250 || this.imageWidth < 250 || (this.page.extension !== 'png' && this.page.extension !== 'jpg' && this.page.extension !== 'jpeg');
    },
    logoTypeError() {
      return this.page.extension !== 'png' && this.page.extension !== '' && this.page.extension !== 'jpg' && this.page.extension !== 'jpeg';
    },
    logoDimensionError() {
      return this.imageSize != 0 && (this.imageHeight < 250 || this.imageWidth < 250);
    },
    logoSizeError() {
      return this.imageSize > 500000;
    },
  },
  methods: {
    clearForm() {
      this.page = {
        image: null,
        extension: "",
        mimeType: "",
        pageImageType: "LOGO",
      };
      this.imageSize = 0,
      this.imageHeight = 0,
      this.imageWidth = 0,
      this.url = null;
      this.$refs.form.reset();
      this.changeDialogStatus();
    },
    fireFileUploadEvent() {
      document.getElementById('uploadLogo').click();
    },
    manuallyOpenFileSelector() {
      let el = this.$refs.uploadLogo.$refs.input;
      el.click();
    },
    uploadImage() {
      this.$feedback.showLoading();

      var formData = new FormData();
      formData.append("image", this.page.image);
      formData.append("fileExtension", this.page.extension);
      formData.append("mimeType", this.page.mimeType);
      formData.append("pageImageType", this.page.pageImageType);
      formData.append("pageId", this.$store.getters.getCustomPage.pageId);

      this.$cppClient.post('/page/update', formData, null, true)
          .then(res => {
            this.$feedback.hideLoading();
            this.$store.commit('setCustomPage', res.data);
          })
          .catch(({response}) => {
            this.$feedback.hideLoading();
            this.$feedback.showFailed(response);
          })
          .finally(() => {
            this.clearForm();
          })
    },
    previewImage() {
      if (this.page.image !== undefined) {
        this.imageSize = this.page.image.size;
        this.page.mimeType = this.page.image.type;
        this.page.extension = this.page.image.name.split('.').pop().toLowerCase();

        this.url = URL.createObjectURL(this.page.image);

        let image = new Image();
        image.src = URL.createObjectURL(event.target.files[0]);

        let app = this;
        //Validate the File Height and Width.
        image.onload = function () {
          app.imageHeight = this.height;
          app.imageWidth = this.width;
        }

        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          this.publishableAsset.extension = this.asset.extension;
          this.publishableAsset.image = reader.result.split(",").pop();
          this.publishableAsset.mimeType = this.asset.mimeType;
        };
      }
    },
  }
}
</script>

<style>
.merchant-base-link {
  display: flex;
  flex-direction: row;
  width: 80%;
}


.merchant-url-layout {
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
  display: flex;
  height: 40px;
  border: solid 1px #dddddd;
  background-color: #dddddd;
  align-items: center;
}

.merchant-url-text {
  margin-left: 6px;
  margin-right: 3px;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.29;
  letter-spacing: normal;
  text-align: left;
  color: #9a9a9a;
  white-space: nowrap;
}

.banner_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.banner_form_title {
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.82;
  letter-spacing: normal;
  text-align: center;
  color: #464646;
}

.banner-image-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  border: solid 1px #f3f3f3;
  background-color: #f3f3f3;
}

.error_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5px
}


.size_error_text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  padding-right: 15px;
  padding-left: 15px;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #d0021b;
}

.logo-upload-requirements {
  color: #777777;
  text-align: center;
}

.logo-upload-warning-note {
  text-align: center;
  font-size: 15px;
  color: #aaaaaa;
  padding: 30px 10px;
}
</style>
