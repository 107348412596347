<template>
  <v-form ref="form" v-model="isValid">
    <div class="address-form">
      <span class="form_title"
            style="margin-top: 60px; margin-bottom: 20px;">Add Address</span>
      <v-text-field
          v-model="address"
          :rules="inputRules"
          counter
          dense
          label="Enter Address"
          maxlength="100"
          outlined
          required
          style="width: 80%"
      ></v-text-field>

      <v-row style="margin-bottom: 50px; margin-top: 25px">
        <span class="cancel-button" @click="clearForm">Cancel</span>
        <v-btn :disabled="!isValid" color="#e2136e" style="color: #FFFFFF"
               @click="updateAddress">
          Update Address
        </v-btn>
      </v-row>
    </div>
  </v-form>
</template>

<script>

export default {
  name: "AddressAddDialogForm",
  props: ['changeDialogStatus'],
  data() {
    return {
      isValid: false,
      inputRules: [
        v => !!v || 'Input is required',
      ],
    }
  },
  mounted() {
    this.pageId = this.$store.getters.getCustomPage.pageId;
  },
  computed: {
    basePath() {
      return this.$store.getters.getPageBasePath;
    },
  },
  methods: {
    clearForm() {
      this.changeDialogStatus();
    },
    updateAddress() {
      this.$feedback.showLoading();

      var formData = new FormData();
      formData.append("contactAddress", this.address);
      formData.append("pageId", this.pageId);

      this.$cppClient.post('/page/update', formData, null, true)
          .then(res => {
            this.$feedback.hideLoading();
            this.$store.commit('setCustomPage', res.data);
          })
          .catch(({response}) => {
            this.$feedback.hideLoading();
            this.$feedback.showFailed(response);
          })
          .finally(() => {
            this.clearForm();
          })
    },
  }
}
</script>

<style>
.address-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form_title {
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.82;
  letter-spacing: normal;
  text-align: center;
  color: #464646;
}
</style>
