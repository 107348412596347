<template>
  <v-form ref="form" v-model="isValid">
    <div class="cover-image-form">
      <span class="form_title"
            style="margin-top: 60px; margin-bottom: 40px;">Custom Page Cover</span>
      <div v-if="page.image || cover">
        <v-img :src="url || cover" style="width: 300px; height: 150px"></v-img>
      </div>
      <div class="error_div" v-if="coverTypeError">
        <span
            class="size_error_text">
          Image format not supported.
        </span>
      </div>
      <div class="error_div" v-if="coverDimensionError">
        <span
            class="size_error_text">
          Image dimension less than 250x750 px.
        </span>
      </div>
      <div class="error_div" v-if="coverSizeError">
        <span
            class="size_error_text">
          Image size needs to be less than 1MB.
        </span>
      </div>
      <v-file-input
          id="uploadCover"
          ref="uploadCover"
          v-model="page.image"
          :rules="inputRules"
          accept="image/png, image/jpg"
          class="d-none"
          required
          @change="previewImage">
      </v-file-input>
      <div class="button-container" style="margin-top: 25px;">
        <v-btn @click="manuallyOpenFileSelector"
               color="#f3f3f3" style="color: #e2136e; padding: 0px 24px;" elevation="0">
          <v-icon style="margin-right: 8px">mdi-attachment</v-icon>
          Upload Cover Photo (PNG, JPG)
        </v-btn>
      </div>

      <v-row style="margin-bottom: 50px; margin-top: 25px">
        <span class="cancel-button" @click="clearForm">Cancel</span>
        <v-btn :disabled="isDisabled" color="#e2136e" style="color: #FFFFFF"
               @click="uploadImage">
          Submit
        </v-btn>
      </v-row>
    </div>
    <div class="cover-upload-requirements">
      Page cover must meet the following requirements:<br/>
      Preferable Dimension: 1500x500 px<br/>
      Preferable Aspect Ratio: 3:1<br/>
      Format: .png or .jpg<br/>
      Size: Within 1MB
    </div>
    <div class="cover-upload-warning-note">
      Uploading any inappropriate image will result in immediate revoke.
    </div>
  </v-form>
</template>

<script>

export default {
  name: "CoverChangeDialogForm",
  props: ['changeDialogStatus', 'cover'],
  data() {
    return {
      page: {
        image: null,
        extension: "",
        mimeType: "",
        pageId: "",
      },
      imageSize: 0,
      imageHeight: 0,
      imageWidth: 0,
      isValid: false,
      url: null,
      inputRules: [
        v => !!v || 'Input is required',
      ],
      formData: null,
    }
  },
  mounted() {
    this.formData = new FormData();
  },
  computed: {
    basePath() {
      return this.$store.getters.getPageBasePath;
    },
    isDisabled() {
      return !this.page.image || this.imageSize > 1000000 || this.imageHeight < 250 || this.imageWidth < 750 || (this.page.extension !== 'png' && this.page.extension !== 'jpg' && this.page.extension !== 'jpeg');
    },
    coverTypeError() {
      return this.page.extension !== 'png' && this.page.extension !== '' && this.page.extension !== 'jpg' && this.page.extension !== 'jpeg';
    },
    coverDimensionError() {
      return this.imageSize != 0 && (this.imageHeight < 250 || this.imageWidth < 750);
    },
    coverSizeError() {
      return this.imageSize > 1000000;
    },
  },
  methods: {
    clearForm() {
      this.page = {
        image: null,
        extension: "",
        mimeType: "",
      };
      this.imageSize = 0,
      this.imageHeight = 0,
      this.imageWidth = 0,
      this.url = null;
      this.$refs.form.reset();
      this.changeDialogStatus();
    },
    fireFileUploadEvent() {
      document.getElementById('uploadCover').click();
    },
    manuallyOpenFileSelector() {
      let el = this.$refs.uploadCover.$refs.input;
      el.click();
    },
    uploadImage() {
      this.$feedback.showLoading();

      var formData = new FormData();
      formData.append("image", this.page.image);
      formData.append("fileExtension", this.page.extension);
      formData.append("mimeType", this.page.mimeType);
      formData.append("pageImageType", "COVER_PHOTO");
      formData.append("pageId", this.$store.getters.getCustomPage.pageId);

      this.$cppClient.post('/page/update', formData, null, true)
          .then(res => {
            this.$feedback.hideLoading();
            this.$store.commit('setCustomPage', res.data);
          })
          .catch(({response}) => {
            this.$feedback.hideLoading();
            this.$feedback.showFailed(response);
          })
          .finally(() => {
            this.clearForm();
          })
    },
    previewImage() {
      if (this.page.image !== undefined) {
        this.imageSize = this.page.image.size;
        this.page.mimeType = this.page.image.type;
        this.page.extension = this.page.image.name.split('.').pop().toLowerCase();

        this.url = URL.createObjectURL(this.page.image);

        let image = new Image();
        image.src = URL.createObjectURL(event.target.files[0]);

        let app = this;
        //Validate the File Height and Width.
        image.onload = function () {
          app.imageHeight = this.height;
          app.imageWidth = this.width;
        }

        var reader = new FileReader();
        reader.readAsDataURL(event.target.files[0]);
        reader.onload = () => {
          this.publishableAsset.extension = this.asset.extension;
          this.publishableAsset.image = reader.result.split(",").pop();
          this.publishableAsset.mimeType = this.asset.mimeType;
        };
      }
    },
  }
}
</script>

<style>
.cover-image-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form_title {
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.82;
  letter-spacing: normal;
  text-align: center;
  color: #464646;
}

.banner-image-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  border: solid 1px #f3f3f3;
  background-color: #f3f3f3;
}

.error_div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 5px
}

.size_error_text {
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  padding-right: 15px;
  padding-left: 15px;
  line-height: 1.28;
  letter-spacing: normal;
  text-align: left;
  color: #d0021b;
}

.cover-upload-requirements {
  color: #777777;
  text-align: center;
}

.cover-upload-warning-note {
  text-align: center;
  font-size: 15px;
  color: #aaaaaa;
  padding: 30px 10px;
}
</style>
