<template>
  <div class="footer-layout">
    <v-container class="container-non-mobile">
      <v-row>
        <img class="bkash-icon" src="./img/bkash-logo-dark.png">
      </v-row>
      <v-row>
        <v-col :cols="footerColValue">
          <p style="color: white;">
            bKash provides safe, convenient and easy ways to make
            payments and money transfer services via mobile phones to both the unbanked and the banked people of
            Bangladesh.
          </p>
          <p style="color: white;">
            At present, bKash is one of the leading Mobile Financial
            Services Provider in the world.
          </p>
          <v-row>
            <img v-if="termsAndConditions" src="./img/google-play-icon.png"
                 style="max-width: 157px; max-height: 46px; margin-right: 5px;" @click="toGooglePlayStoreMerchantApp">
            <img v-if="!termsAndConditions" src="./img/google-play-icon.png"
                 style="max-width: 157px; max-height: 46px; margin-right: 5px;" @click="toGooglePlayStore">
            <img v-if="!termsAndConditions" src="./img/app-store-icon.png" style="max-width: 157px; max-height: 46px;"
                 @click="toApplePlayStore">
          </v-row>
        </v-col>
        <v-col :cols="footerColValue" class="footer-contact" style="">
          <h4 style="color: white;">Contact Us at</h4>
          <p style="color: white;">16247 or 02-55663001</p>
          <h4 style="color: white;">Email Us at</h4>
          <p style="color: white;">support.shop@bkash.com</p>
          <p v-if="termsAndConditions" style="color: white;">Report Suspicious Activity - <span @click="goToStrSar"
                                                                                                style="cursor: pointer;color: #e2136e;">HERE</span>
          </p>
          <p v-if="termsAndConditions" @click="toTermsAndConditions" style="color: #e2136e;cursor: pointer;">Terms &
            Conditions</p>
        </v-col>
      </v-row>
      <v-row>
        <v-divider style="opacity: 0.25;
  border: solid 1px; border-color: white;"></v-divider>
      </v-row>
      <v-row style="justify-content: center">
        <v-col style="align-self: end;">
          <p style="color: white;">© {{ new Date().getFullYear() }} bKash, All Right Reserved </p>
        </v-col>
        <v-col align="right">
          <v-btn
              color="white"
              fab
              height="35"
              href="https://www.facebook.com/bkashlimited/"
              style="margin: 5px;"
              width="35"
          >
            <v-icon
                dark
                size="25"
            >
              mdi-facebook
            </v-icon>
          </v-btn>

          <v-btn
              color="white"
              fab
              height="35"
              href="https://twitter.com/bkash_ltd?lang=en"
              style="margin: 5px;"
              width="35"
          >
            <v-icon
                dark
                size="25"
            >
              mdi-twitter
            </v-icon>
          </v-btn>

          <v-btn
              color="white"
              fab
              height="35"
              href="https://www.youtube.com/c/bKashLimited"
              style="margin: 5px;"
              width="35"
          >
            <v-icon
                dark
                size="25"
            >
              mdi-youtube
            </v-icon>
          </v-btn>
          <v-btn
              color="white"
              fab
              height="35"
              href="https://www.instagram.com/bkash.ltd/"
              style="margin: 5px;"
              width="35"
          >
            <v-icon
                dark
                size="25"
            >
              mdi-instagram
            </v-icon>
          </v-btn>
          <v-btn
              color="white"
              fab
              height="35"
              href="https://www.linkedin.com/company/bkash-limited/"
              style="margin: 5px;"
              width="35"
          >
            <v-icon
                dark
                size="25"
            >
              mdi-linkedin
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-container class="container-mobile">
      <v-row>
        <v-col cols="6" style="text-align: left">
          <h4 style="color: white;">Contact Us at</h4>
          <p style="color: white;">16247 or 02-55663001</p>
        </v-col>
        <v-col cols="6" style="text-align: right">
          <h4 style="color: white;">Email Us at</h4>
          <p style="color: white;">support.shop@bkash.com</p>
        </v-col>
      </v-row>
      <v-row style="justify-content: center; margin-bottom: 10px;"><h4 style="color: white;">Download bKash
        {{ termsAndConditions ? 'Merchant' : '' }} App</h4>
      </v-row>
      <v-row style="justify-content: center; margin-bottom: 15px;">
        <img v-if="termsAndConditions" src="./img/google-play-icon.png"
             style="max-width: 157px; max-height: 46px; margin-right: 5px;" @click="toGooglePlayStoreMerchantApp">
        <img v-if="!termsAndConditions" src="./img/google-play-icon.png"
             style="max-width: 157px; max-height: 46px; margin-right: 5px;" @click="toGooglePlayStore">
        <img v-if="!termsAndConditions" src="./img/app-store-icon.png" style="max-width: 157px; max-height: 46px;"
             @click="toApplePlayStore">
      </v-row>
      <v-row style="justify-content: center;">
        <img class="bkash-icon" src="./img/bkash-logo-dark.png">
      </v-row>
      <v-row>
        <v-col :cols="footerColValue">
          <p style="color: white; text-align: center;">
            bKash provides safe, convenient and easy ways to make
            payments and money transfer services via mobile phones to both the unbanked and the banked people of
            Bangladesh.
          </p>
          <p style="color: white; text-align: center;">
            At present, bKash is one of the leading Mobile Financial
            Services Provider in the world.
          </p>
        </v-col>
      </v-row>
      <v-row v-if="termsAndConditions">
        <v-col cols="6" style="text-align: left">
          <p style="color: white;"><span @click="goToStrSar" style="cursor: pointer;color: #e2136e;">Report</span>
            Suspicious Activity</p>
        </v-col>
        <v-col cols="6" style="text-align: right">
          <p @click="toTermsAndConditions" style="cursor: pointer;color: #e2136e;">Terms & Conditions</p>
        </v-col>
      </v-row>
      <v-row>
        <v-divider style="opacity: 0.25;
  border: solid 1px; border-color: white;"></v-divider>
      </v-row>
      <v-row style="justify-content: center;">
        <v-btn
            color="white"
            fab
            height="35"
            href="https://www.facebook.com/bkashlimited/"
            style="margin: 5px;"
            width="35"
        >
          <v-icon
              dark
              size="25"
          >
            mdi-facebook
          </v-icon>
        </v-btn>

        <v-btn
            color="white"
            fab
            height="35"
            href="https://twitter.com/bkash_ltd?lang=en"
            style="margin: 5px;"
            width="35"
        >
          <v-icon
              dark
              size="25"
          >
            mdi-twitter
          </v-icon>
        </v-btn>

        <v-btn
            color="white"
            fab
            height="35"
            href="https://www.youtube.com/c/bKashLimited"
            style="margin: 5px;"
            width="35"
        >
          <v-icon
              dark
              size="25"
          >
            mdi-youtube
          </v-icon>
        </v-btn>
        <v-btn
            color="white"
            fab
            height="35"
            href="https://www.instagram.com/bkash.ltd/"
            style="margin: 5px;"
            width="35"
        >
          <v-icon
              dark
              size="25"
          >
            mdi-instagram
          </v-icon>
        </v-btn>
        <v-btn
            color="white"
            fab
            height="35"
            href="https://www.linkedin.com/company/bkash-limited/"
            style="margin: 5px;"
            width="35"
        >
          <v-icon
              dark
              size="25"
          >
            mdi-linkedin
          </v-icon>
        </v-btn>
      </v-row>
      <v-row style="justify-content: center;">
        <p style="color: white;">© {{ new Date().getFullYear() }} bKash, All Right Reserved </p>
      </v-row>
    </v-container>
  </div>
</template>

<script>

export default {
  name: "CustomPageFooter",
  props: ['termsAndConditions'],
  computed: {
    footerColValue() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 12
        default:
          return 6
      }
    },
  },
  methods: {
    toGooglePlayStoreMerchantApp() {
      window.open("https://play.google.com/store/apps/details?id=com.bKash.merchantapp", '_blank');
    },
    toGooglePlayStore() {
      window.open("https://play.google.com/store/apps/details?id=com.bKash.customerapp", '_blank');
    },
    toApplePlayStore() {
      window.open("https://apps.apple.com/us/app/bkash/id1351183172", '_blank');
    },
    goToStrSar() {
      let routeData = this.$router.resolve({path: '/strsarform'});
      window.open(routeData.href, '_blank');
    },
    toTermsAndConditions() {
      window.open(this.termsAndConditions, '_blank');
    },
  },
}
</script>

<style lang="scss" scoped>
$LaptopW: 1440px;
$MedW: 1024px;
$tabLS: 900px;
$mobileW: 750px;

.container {
  max-width: 1185px;
}

.footer-layout {
  background-color: #1e1e1e;
  display: flex;
  position: relative;
  margin: 29.5px 0 0;
  width: 100%;
  //height: 470px;
  margin-top: 2px;
}

.container-mobile {
  display: none;
}

p {
  font-size: 14px;
}

.footer-contact {
  text-align: right
}

.bkash-icon {
  width: 180px;
  height: 83px;
  margin-bottom: 20px;
  background-size: cover;
}

@media screen and (max-width: $mobileW) {
  .container-mobile {
    display: block;
  }
  .container-non-mobile {
    display: none;
  }
  .footer-layout {
    padding: 5px;
  }
  .footer-contact {
    text-align: left;
  }
  .bkash-icon {
    width: 135px;
    height: 62px;
    margin-bottom: 10px;
  }
}
</style>
