<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        persistent
        width="680"
    >
      <v-card>
        <address-add-dialog-form :address="address" :changeDialogStatus="closeDialog"></address-add-dialog-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AddressAddDialogForm from './AddressAddDialogForm.vue';

export default {
  name: "AddressAddDialog",
  components: {
    AddressAddDialogForm,
  },

  data() {
    return {
      address: "",
      dialog: false,
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    showUploadInfoDialog() {
      this.dialog = true;
    }
  },
  mounted() {
    this.$eventBus.$on('showAddressAddDialog', () => {
      this.address = this.$store.getters.getCustomPage.contactAddress;
      this.showUploadInfoDialog();
    });
  }
}
</script>

<style>
</style>
