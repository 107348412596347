<template>
  <div class="logo-container">
    <v-img
        :lazy-src="require('./img/custom-page-logo-default.png')"
        :src="logoPic"
        class="logo-pic"
        style="border-style: solid; border-width: 5px; border-color: #e2136e; border-radius: 50%; background: contain; box-shadow: 5px 5px 25px grey;">

      <template v-slot:placeholder>
        <v-row
            align="center"
            class="fill-height ma-0"
            justify="center"
        >
          <v-progress-circular
              color="grey lighten-5"
              indeterminate
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>
    <v-btn
        v-if="editMode"
        class="logo-button"
        color="primary"
        elevation="2"
        fab
        small
        @click="showUploadLogoDialog"
    >
      <v-icon
          dark
      >
        mdi-camera
      </v-icon>
    </v-btn>
  </div>
</template>

<script>

export default {
  name: "CustomPageLogo",
  props: ['editMode', 'logo'],
  data() {
    return {
      // logo: "https://cdn.vuetifyjs.com/images/profiles/marcus.jpg",
    }
  },
  mounted() {
    // if (this.customPageHeaderDetails.logo !== null)
    //   this.logo = this.customPageHeaderDetails.logo;
  },
  computed: {
    coverPic() {
      if (this.cover) return this.cover;
      return "https://sit-cpp-images.s3-ap-southeast-1.amazonaws.com/news-letter/4e7d4b21-c2c0-466b-b5d8-fc87ca416355.png"
    },
    logoPic() {
      if (this.logo) return this.logo;
      return require('./img/custom-page-logo-default.png');
    }
  },
  methods: {
    showUploadLogoDialog() {
      this.$eventBus.$emit('showUploadLogoDialog');
    },
    showUploadCoverDialog() {
      this.$eventBus.$emit('showUploadCoverDialog');
    },
    showChangeTitleDialog() {
      this.$eventBus.$emit('showChangeTitleDialog');
    },
  },

}
</script>

<style lang="scss" scoped>
$LaptopW: 1440px;
$MedW: 1024px;
$tabLS: 900px;
$mobileW: 750px;

.logo-container {
  display: flex;
  width: 230px;
  height: 230px;
  margin: 2px;
  z-index: 2;
}

.logo-pic {
  max-height: 230px;
  max-width: 230px;
  min-height: 230px;
  min-width: 230px;
  background-color: #f7f7f7;
}

.logo-button {
  z-index: 3;
  right: 25%;
  top: 75%;
}

@media screen and (max-width: $mobileW) {
  .logo-container {
    width: 150px;
    height: 150px;
  }
  .logo-pic {
    max-height: 150px;
    max-width: 150px;
    min-height: 150px;
    min-width: 150px;
  }
  .logo-button {
    left: -44px;
    top: 108px;
  }
}
</style>
