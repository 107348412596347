<template>
  <div class="page-container">
  <div class="preview-layout">
    <div class="header-container">
      <custom-page-header
          :cover="customPage.coverPhoto"
          :editMode="editMode"
          :logo="customPage.logo"
          :title="customPage.title"
      ></custom-page-header>
    </div>
    <div>
      <v-container style="max-width: 100%; padding: 0px 12px 12px;">
        <v-row align="center">
          <v-col align="center" cols="12">

            <v-btn
                v-if="socialLinks[0].length > 0"
                :key="`social-btn-`+socialLinks[0]"
                :height="socialButtonSize"
                :href="socialLinks[0]"
                :width="socialButtonSize"
                color="primary"
                fab
                style="margin: 5px;"
            >
              <v-icon
                  :size="socialButtonIconSize"
                  dark
              >
                mdi-facebook
              </v-icon>
            </v-btn>

            <v-btn
                v-if="socialLinks[1].length > 0"
                :key="`social-btn-`+socialLinks[1]"
                :height="socialButtonSize"
                :href="socialLinks[1]"
                :width="socialButtonSize"
                color="primary"
                fab
                style="margin: 5px;"
            >
              <v-icon
                  :size="socialButtonIconSize"
                  dark
              >
                mdi-whatsapp
              </v-icon>
            </v-btn>

            <v-btn
                v-if="socialLinks[2].length > 0"
                :key="`social-btn-`+socialLinks[2]"
                :height="socialButtonSize"
                :href="socialLinks[2]"
                :width="socialButtonSize"
                color="primary"
                fab
                style="margin: 5px;"
            >
              <v-icon
                  :size="socialButtonIconSize"
                  dark
              >
                mdi-youtube
              </v-icon>
            </v-btn>
            <v-btn
                v-if="socialLinks[3].length > 0"
                :key="`social-btn-`+socialLinks[3]"
                :height="socialButtonSize"
                :href="socialLinks[3]"
                :width="socialButtonSize"
                color="primary"
                fab
                style="margin: 5px;"
            >
              <v-icon
                  :size="socialButtonIconSize"
                  dark
              >
                mdi-instagram
              </v-icon>
            </v-btn>

          </v-col>
          <div v-if="editMode" class="social-edit">
            <v-btn
                v-if="editMode"
                color="primary"
                elevation="2"
                fab
                small
                @click="showAddSocialLinkDialog"
            >
              <v-icon
                  dark
              >
                mdi-plus
              </v-icon>
            </v-btn>
            Add Social
          </div>
        </v-row>
      </v-container>
      <v-container>
        <v-row>
          <v-btn-toggle
              v-model="categoryTab"
              color="#e2136e"
              group
              mandatory
              style="flex-wrap: wrap;
              flex: 1 1 auto;
              margin-right: -12px;
              margin-left: -12px;
              justify-content: center;
              "
              tile
          >
            <v-btn
                v-for="category in customPage.productCategories"
                :key="category.productCategoryId"
                class="category-text category-divider"
            >
              {{ category.name }}
              <v-icon v-if='editMode && category.name !=="Popular"' :size="categoryDeleteIcon" @click="showDeleteCategoryDialog(category.productCategoryId)" >mdi-close-circle</v-icon>
            </v-btn>
          </v-btn-toggle>
        </v-row>
      </v-container>
      <div v-if="editMode" class="category-edit">
        <v-btn
            v-if="editMode"
            color="primary"
            elevation="2"
            fab
            small
            @click="showAddCategoryDialog"
        >
          <v-icon
              dark
          >
            mdi-plus
          </v-icon>
        </v-btn>
        Add Product Categories
      </div>

      <v-tabs-items v-model="categoryTab">
        <v-tab-item
            v-for="category in customPage.productCategories"
            :key="category.productCategoryId"
        >
          <div v-if="editMode && category.productCategoryId !== '1000'" class="product-add-btn"
               @click="showAddProductDialog(category.productCategoryId)">
            <v-btn
                v-if="editMode"
                color="primary"
                elevation="2"
                fab
                small
            >
              <v-icon
                  dark
              >
                mdi-plus
              </v-icon>
            </v-btn>
            Add Product
          </div>
          <product-list
              v-if=" category.productCategoryId === '1000' && customPage.products && customPage.products.filter(val => val.isPopular).length > 0"
              :key="category.categoryId"
              :category="category.productCategoryId"
              :productList="customPage.products && customPage.products.filter(val => val.isPopular)"
              :socialLinks="socialLinks">
          </product-list>
          <product-list
              v-if="category.productCategoryId !== '1000'"
              :key="category.categoryId"
              :category="category.productCategoryId"
              :editMode="editMode"
              :productList="customPage.products && customPage.products.filter(val => val.productCategoryId === category.productCategoryId )"
              :socialLinks="socialLinks">
          </product-list>
        </v-tab-item>
      </v-tabs-items>
      <div class="another-amount">
        <v-container style="max-width: 100%; padding: 0;">
          <v-row style="justify-content: center;">
            <span class="another-amount-text">Want to pay another amount? </span>
            <div style="margin-left: 15px; align-self: center;">
              <v-btn
                  :v-bind="payNowBtnSize"
                  class="another-amount-pay-button"
                  color="primary"
                  rounded
                  @click="defaultPaymentClicked(customPage.merchantBasePath, customPage.merchantUrlFragment)"
              >
                Pay now
              </v-btn>
            </div>
          </v-row>
        </v-container>
      </div>
      <div class="about-section">
        <v-container style="max-width: 100%">
          <v-row
              align="center">
            <v-col :cols="aboutColValue">
              <div v-if="!editMode" class="about-section-title">About Us</div>
              <div v-if="editMode && customPage.about && customPage.about.length > 0"
                   class="about-section-title">About Us
                <v-btn
                    color="primary"
                    elevation="2"
                    fab
                    small
                    @click="showAddAboutDialog"
                >
                  <v-icon
                      dark
                  >
                    mdi-pencil
                  </v-icon>
                </v-btn>
              </div>
              <div v-if="editMode && !customPage.about" class="about-section-title">
                <v-btn

                    color="primary"
                    elevation="2"
                    fab
                    small
                    @click="showAddAboutDialog"
                >
                  <v-icon
                      dark
                  >
                    mdi-plus
                  </v-icon>
                </v-btn>
                Add About Us
              </div>
              <div class="about-section-text" v-html="customPage.about"></div>
            </v-col>

            <v-col :cols="merchantInfoColValue">
              <div style="font-size: 30px;font-weight: 500;color: #e2136e; margin-bottom: 8px;">
                {{ customPage.merchantName ? customPage.merchantName : this.$store.getters.getUserName }}
              </div>
              <div style="font-size: 24px;color: #e2136e; margin-bottom: 5px;">
                {{ customPage.contactEmail ? customPage.contactEmail : '' }}
              </div>
              <div style="font-size: 24px;color: #e2136e; margin-bottom: 5px;">{{ customPage.contactWebsite }}
                <v-btn
                    v-if="editMode && customPage.contactWebsite && customPage.contactWebsite.length > 0"
                    color="primary"
                    elevation="2"
                    fab
                    x-small
                    @click="showAddWebsiteDialog"
                >
                  <v-icon
                      dark
                  >
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <div v-if="editMode && !customPage.contactWebsite">
                  <v-btn
                      color="primary"
                      elevation="2"
                      fab
                      x-small
                      @click="showAddWebsiteDialog"
                  >
                    <v-icon
                        dark
                    >
                      mdi-plus
                    </v-icon>
                  </v-btn>
                  Add Website
                </div>
              </div>
              <div style="font-size: 24px; margin-bottom: 5px;">
                {{ customPage.contactPhone ? customPage.contactPhone : '' }}
              </div>
              <div style="font-size: 24px;  margin-bottom: 8px;">{{ customPage.contactAddress }}
                <v-btn
                    v-if="editMode && customPage.contactAddress && customPage.contactAddress.length > 0"
                    color="primary"
                    elevation="2"
                    fab
                    x-small
                    @click="showAddAddressDialog"
                >
                  <v-icon
                      dark
                  >
                    mdi-pencil
                  </v-icon>
                </v-btn>
                <div v-if="editMode && !customPage.contactAddress">
                  <v-btn
                      color="primary"
                      elevation="2"
                      fab
                      x-small
                      @click="showAddAddressDialog"
                  >
                    <v-icon
                        dark
                    >
                      mdi-plus
                    </v-icon>
                  </v-btn>
                  Add Address
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
      <custom-page-footer v-if="!editMode"></custom-page-footer>
    </div>
  </div>
  </div>
</template>

<script>
import CustomPageFooter from './CustomPageFooter.vue';
import CustomPageHeader from './CustomPageHeader.vue';
import ProductList from './ProductList.vue';

export default {
  name: "CustomPageContainer",
  components: {
    CustomPageHeader,
    ProductList,
    CustomPageFooter,
  },
  props: ['editMode', 'customPage', 'customerPortalBasePath'],
  data() {
    return {
      categoryTab: 0,
      merchantName: "",
      customPageHeaderDetails: {
        title: "",
        logo: null,
        urlFragment: "",
        coverPhoto: null,
      },
    }
  },
  mounted() {
  },
  beforeUpdate() {
    this.merchantName = this.$store.getters.getUserName;
    this.categoryList = this.customPage.productCategories;
  },
  computed: {
    socialLinks() {
      let socialLinks = this.customPage.socialLinks ? this.customPage.socialLinks : ",,,";
      return socialLinks.split(',');
    },
    payNowBtnSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 'x-small'
        case 'sm':
          return 'small'
        case 'md':
          return 'large'
        case 'lg':
          return 'x-large'
        case 'xl':
          return 'x-large'
      }
    },
    aboutColValue() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 12
        default:
          return 9
      }
    },
    merchantInfoColValue() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 12
        default:
          return 3
      }
    },
    socialButtonSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 40
        case 'md':
          return 72
        case 'lg':
          return 72
        case 'xl':
          return 72
        default:
          return 72
      }
    },
    socialButtonIconSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 27
        default:
          return 50
      }
    },
    categoryDeleteIcon() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 15
        case 'sm':
          return 20
        case 'md':
          return 20
        case 'lg':
          return 20
        case 'xl':
          return 20
      }
    },
  },
  methods: {
    showAddSocialLinkDialog() {
      this.$eventBus.$emit('showAddSocialLinkDialog');
    },
    showAddCategoryDialog() {
      this.$eventBus.$emit('showCategoryAddDialog');
    },
    showDeleteCategoryDialog(categoryId) {
      this.$eventBus.$emit('showCategoryDeleteDialog', categoryId);
    },
    showAddProductDialog(categoryId) {
      this.$eventBus.$emit('showProductAddDialog', categoryId);
    },
    showAddAboutDialog() {
      this.$eventBus.$emit('showAboutAddDialog');
    },
    showAddEmailDialog() {
      this.$eventBus.$emit('showEmailAddDialog');
    },
    showAddPhoneDialog() {
      this.$eventBus.$emit('showPhoneAddDialog');
    },
    showAddWebsiteDialog() {
      this.$eventBus.$emit('showWebsiteAddDialog');
    },
    showAddAddressDialog() {
      this.$eventBus.$emit('showAddressAddDialog');
    },
    filterByCategory(product, categoryId) {
      return product.productCategoryId === categoryId;
    },
    defaultPaymentClicked(merchantBasePath, merchantUrlFragment) {
      window.open(this.customerPortalBasePath+merchantBasePath+"/"+merchantUrlFragment, '_blank');
    }
  }
}
</script>

<style lang="scss" scoped>
$LaptopW: 1440px;
$MedW: 1024px;
$tabLS: 900px;
$mobileW: 750px;

.page-container {
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  //background-image: url('./img/test-pattern.png');
  //background-color: #E6E6FA;
  background-color: #F9F9F9;
}

.preview-layout {
  background-color: #FFFFFF;
  min-height: 320px;
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 1250px;
  margin-left: auto;
  margin-right: auto;
}

.header-container {
  margin-bottom: 30px;
}

.social-edit {
  width: 100%;
  height: 74px;
  text-align: center;
  font-family: Roboto;
  font-size: 40px;
  font-weight: bold;
}

.category-edit {
  width: 100%;
  height: 74px;
  text-align: center;
  font-family: Roboto;
  font-size: 40px;
  font-weight: bold;
}

.product-add-btn {
  width: 90%;
  text-align: right;
  font-family: Roboto;
  font-size: 24px;
  font-weight: bold;
}

.another-amount-text {
  font-size: 38px;
  font-weight: 500;
  color: #464646;
}

.another-amount-pay-button {
  font-size: 27px;
  font-weight: 500;
  min-height: 48px;
}

.about-section {
  width: 100%;
  //height: 485px;
  margin: 61px 0 5px;
  padding: 50px 136.3px 21px 96px;
  background-color: #f7f7f7;

  .about-section-title {
    font-size: 50px;
    font-weight: 800;
  }

  .about-section-text {
    font-size: 20px;
  }
}

.category-text {
  font-size: 30px;
}

.category-divider {
  //border-right: 1px solid #e2136e;
  border-right: 2px solid #333 !important;
}

.category-divider:last-child {
  border-right: 0 !important;
}

.another-amount {
  margin: 26px 0 5px;
  padding: 5px 136.3px 5px 96px;
  align-items: center;
  justify-content: center;
  display: grid;
}

@media screen and (max-width: $mobileW) {
  .header-container {
    margin-bottom: 0;
  }
  .social-edit {
    font-size: 30px;
  }
  .category-text {
    font-size: 20px;
  }
  .category-edit {
    margin-top: 10px;
    font-size: 20px;
  }
  .product-add-btn {
    font-size: 15px;
  }
  .another-amount {
    padding: 5px 15px 5px 15px;
  }
  .another-amount-text {
    font-size: 25px;
  }
  .another-amount-pay-button {
    font-size: 14px;
    font-weight: 500;
    min-height: 30px;
  }
  .about-section {
    padding: 4px;
    text-align: center;

    .about-section-title {
      font-size: 30px;
      font-weight: 800;
    }

    .about-section-text {
      font-size: 16px;
    }
  }
}
</style>
