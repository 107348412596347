<template>
  <div justify="center">
    <v-row justify="center">
      <v-dialog
          v-model="dialog"
          max-width="490"
      >
        <v-card>
          <v-card-title class="text-h5"
                        style="text-align: center; align-content: center; justify-content: center;margin-bottom: 10px;">
            <span v-if="!links" style="margin-top: 30px; word-break: normal;">Have you contacted your merchant for product availability ?</span>
            <span v-if="links" style="margin-top: 30px; word-break: normal;">Contact your merchant via social links below</span>
          </v-card-title>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-container>
              <v-row v-if="links" align="center">
                <v-col align="center" cols="12">
                  <v-btn
                      v-if="socialLinks[0].length > 0"
                      :key="`social-btn-`+socialLinks[0]"
                      :height="socialButtonSize"
                      :href="socialLinks[0]"
                      :width="socialButtonSize"
                      color="primary"
                      fab
                      style="margin: 5px;"
                  >
                    <v-icon
                        :size="socialButtonIconSize"
                        dark
                    >
                      mdi-facebook
                    </v-icon>
                  </v-btn>

                  <v-btn
                      v-if="socialLinks[1].length > 0"
                      :key="`social-btn-`+socialLinks[1]"
                      :height="socialButtonSize"
                      :href="socialLinks[1]"
                      :width="socialButtonSize"
                      color="primary"
                      fab
                      style="margin: 5px;"
                  >
                    <v-icon
                        :size="socialButtonIconSize"
                        dark
                    >
                      mdi-whatsapp
                    </v-icon>
                  </v-btn>

                  <v-btn
                      v-if="socialLinks[2].length > 0"
                      :key="`social-btn-`+socialLinks[2]"
                      :height="socialButtonSize"
                      :href="socialLinks[2]"
                      :width="socialButtonSize"
                      color="primary"
                      fab
                      style="margin: 5px;"
                  >
                    <v-icon
                        :size="socialButtonIconSize"
                        dark
                    >
                      mdi-youtube
                    </v-icon>
                  </v-btn>
                  <v-btn
                      v-if="socialLinks[3].length > 0"
                      :key="`social-btn-`+socialLinks[3]"
                      :height="socialButtonSize"
                      :href="socialLinks[3]"
                      :width="socialButtonSize"
                      color="primary"
                      fab
                      style="margin: 5px;"
                  >
                    <v-icon
                        :size="socialButtonIconSize"
                        dark
                    >
                      mdi-instagram
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row align="center" style="margin-bottom: 50px; margin-top: 25px;">
                <v-col align="center" cols="12">
                  <v-btn v-if="!links" class="product-pay" color="#e2136e" rounded style="color: #FFFFFF; margin: 0 20px;"
                         @click="showSocialLinks">
                    No
                  </v-btn>
                  <v-btn class="product-pay" color="#e2136e" rounded style="color: #FFFFFF; margin: 0 20px;"
                         @click="proceedToPay(product.paymentLink)">
                    {{ !links ? "Yes" : "Pay now" }}
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-card
        :loading="loading"
        :max-width="productImageWidth"
        class="mx-auto"
        style="overflow: hidden;"
    >
      <template slot="progress">
        <v-progress-linear
            color="deep-purple"
            height="10"
            indeterminate
        ></v-progress-linear>
      </template>

      <v-img
          :height="productImageHeight"
          :src="productPic"
          :width="productImageWidth"
          class="product-image"
          @click="productHyperLink(product.hyperLink)"
      >
        <div v-if="!product.image" class="empty-product-image">{{ product.name }}</div>
      </v-img>


      <v-card-title class="product-title">
        {{ product.name }}
        <v-btn v-if="editMode" class="product-edit-button" color="pink"
               dark fab @click="showAddProductDialog(product.productCategoryId, product.productId)">
          <v-icon :size="productEditIcon">mdi-pencil</v-icon>
        </v-btn>
        <v-btn v-if="editMode" class="product-delete-button" color="pink"
               dark fab @click="showDeleteProductDialog(product.productId)">
          <v-icon :size="productEditIcon">mdi-close-circle</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="product-description">
        <v-row
            align="center"
            class="mx-0"
            justify="space-between"
        >
          <div class="price" style="padding-top: 10px; padding-bottom: 5px;">৳ {{ product.price }}</div>

          <div>
            <v-btn
                v-if="product.paymentLink && product.paymentLink.length > 0"
                class="product-pay"
                color="primary"
                rounded
                @click="payNowClicked(product.paymentLink)"
            >
              Pay now
            </v-btn>
          </div>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>

export default {
  name: "ProductCard",
  props: ['editMode', 'product', 'socialLinks'],
  data() {
    return {
      dialog: false,
      links: false,
    }
  },
  computed: {
    productPic() {
      if (this.product.image) return this.product.image;
      return require('./img/custom-page-logo-default.png');
    },
    productImageWidth() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 160
        case 'sm':
          return 160
        case 'md':
          return 300
        case 'lg':
          return 300
        case 'xl':
          return 300
      }
    },
    productImageHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 150
        case 'sm':
          return 150
        case 'md':
          return 264
        case 'lg':
          return 264
        case 'xl':
          return 264
      }
    },
    productEditIcon() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 15
        case 'sm':
          return 24
        case 'md':
          return 24
        case 'lg':
          return 24
        case 'xl':
          return 24
      }
    },
    socialButtonSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 40
        case 'md':
          return 55
        case 'lg':
          return 55
        case 'xl':
          return 55
        default:
          return 55
      }
    },
    socialButtonIconSize() {
      switch (this.$vuetify.breakpoint.name) {
        case 'xs':
          return 27
        default:
          return 37.5
      }
    },
  },
  methods: {
    showAddProductDialog(categoryId, productId) {
      console.log(categoryId);
      this.$eventBus.$emit('showProductEditDialog', categoryId, productId);
    },
    showDeleteProductDialog(productId) {
      this.$eventBus.$emit('showProductDeleteDialog', productId);
    },
    payNowClicked(link) {
      this.dialog = true;
      this.links = false;
    },
    proceedToPay(link) {
      window.open(link, '_blank');
    },
    showSocialLinks() {
      this.links = true;
    },
    productHyperLink(link) {
      if (link) window.open(link, '_blank');
    },
  },

}
</script>

<style lang="scss" scoped>
$LaptopW: 1440px;
$MedW: 1024px;
$tabLS: 900px;
$mobileW: 750px;

.product-image {
  transition: transform .2s;
  overflow: hidden;
  cursor: pointer;
  text-align: center;
  align-items: center;
  font-size: 30px;
  font-weight: bold;
  color: #e2136e;
}

.product-image:hover {
  transform: scale(1.2);
}

.empty-product-image {
  border: 2px solid #e2136e;
  height: 264px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.product-title {
  font-size: 28px;
  position: relative;
  background-color: white;
}

.price {
  font-size: 35px;
  color: #e2136e;
}

.product-pay {
  width: 96px;
  min-width: 64px;
  height: 36px;
}

.product-edit-button {
  width: 36px;
  min-width: 36px;
  height: 36px;
  max-height: 36px;
  position: absolute;
  top: -20px;
  right: 45px;
}

.product-delete-button {
  width: 36px;
  min-width: 36px;
  height: 36px;
  max-height: 36px;
  position: absolute;
  top: -20px;
  right: 5px;
}

@media screen and (max-width: $mobileW) {
  .product-title {
    font-size: 16px;
    padding: 5px;
  }
  .price {
    font-size: 15px;
  }
  .product-pay {
    width: 64px;
    min-width: 64px;
    height: 20px;
    max-height: 20px;
    font-size: 8px;
  }
  .product-description {
    padding: 5px;
  }
  .product-edit-button {
    width: 25px;
    min-width: 25px;
    height: 25px;
    max-height: 25px;
    top: -12px;
    right: 10px;
  }
  .product-delete-button {
    width: 25px;
    min-width: 25px;
    height: 25px;
    max-height: 25px;
    top: -12px;
    right: 36px;
  }
  .empty-product-image {
    height: 150px;
  }
}
</style>
