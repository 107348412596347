<template>
  <v-form ref="form" v-model="isValid">
    <div class="email-form">
      <span class="form_title"
            style="margin-top: 60px; margin-bottom: 20px;">Add Email</span>
      <v-text-field
          v-model="email"
          :rules="emailRules"
          counter
          dense
          label="Enter Email"
          maxlength="50"
          outlined
          required
          style="width: 80%"
      ></v-text-field>

      <v-row style="margin-bottom: 50px; margin-top: 25px">
        <span class="cancel-button" @click="clearForm">Cancel</span>
        <v-btn :disabled="!isValid" color="#e2136e" style="color: #FFFFFF"
               @click="addEmail">
          Update Email
        </v-btn>
      </v-row>
    </div>
  </v-form>
</template>

<script>
import {EMAIL_RULE} from "cpp-js-util/CppUtil";

export default {
  name: "EmailAddDialogForm",
  props: ['changeDialogStatus', 'email'],
  data() {
    return {
      isValid: false,
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => EMAIL_RULE.test(v) || "E-mail must be valid",
      ],
      formData: null,
    }
  },
  mounted() {
    this.formData = new FormData();
    this.pageId = this.$store.getters.getCustomPage.pageId;
  },
  computed: {
    basePath() {
      return this.$store.getters.getPageBasePath;
    },
  },
  methods: {
    clearForm() {
      this.changeDialogStatus();
    },
    addEmail() {
      this.$feedback.showLoading();

      var formData = new FormData();
      formData.append("contactEmail", this.email);
      formData.append("pageId", this.pageId);

      this.$cppClient.post('/page/update', formData, null, true)
          .then(res => {
            this.$feedback.hideLoading();
            this.$store.commit('setCustomPage', res.data);
          })
          .catch(({response}) => {
            this.$feedback.hideLoading();
            this.$feedback.showFailed(response);
          })
          .finally(() => {
            this.clearForm();
          })
    },
  }
}
</script>

<style>
.email-form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form_title {
  font-size: 22px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 0.82;
  letter-spacing: normal;
  text-align: center;
  color: #464646;
}
</style>
