<template>
  <div class="text-center">
    <v-dialog
        v-model="dialog"
        persistent
        width="800"
    >
      <v-card>
        <about-add-dialog-form :about="about" :changeDialogStatus="closeDialog"></about-add-dialog-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import AboutAddDialogForm from './AboutAddDialogForm.vue';

export default {
  name: "AboutAddDialog",
  components: {
    AboutAddDialogForm,
  },

  data() {
    return {
      about: "",
      dialog: false,
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
    },
    showUploadInfoDialog() {
      this.dialog = true;
    }
  },
  mounted() {
    this.$eventBus.$on('showAboutAddDialog', () => {
      this.about = this.$store.getters.getCustomPage.about;
      this.showUploadInfoDialog();
    });
  }

}
</script>

<style>
</style>
